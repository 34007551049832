import { HttpResponse } from 'msw';

export function getCommuntyBoardMenuesolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 4369,
      "name" : "보고서",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : true
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4370,
      "name" : "기획서",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : true
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4371,
      "name" : "디자인",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : true
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4373,
      "name" : "회의록",
      "type" : "STREAM",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : true
      }
    }, {
      "id" : 4374,
      "name" : "개발 파트별 공유 자료",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : true
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4400,
      "name" : "파트간 이슈 협의",
      "type" : "STREAM",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : true
      }
    }, {
      "id" : 4600,
      "name" : "사업부 공유 문서",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : true
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    } ]
  });
}

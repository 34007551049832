import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveVehicleLog: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));
  const respData = [
    {
      "id": "4471c9bb-fbb7-4d9d-acab-ec08e0fb3ebc",
      "type": "개인",
      "status": "WRITING",
      "name": "SM6",
      "number": "353루 5015",
      "purpose": "출근",
      "logDateTime": "2024-11-30T09:00:00",
      "distance": 10,
      "starting": "다우기술",
      "destination": "모란역",
      "remarks": '비고란은 이렇게 작성됨'
    },
    {
      "id": "81f57d9f-5a4c-4396-baf4-24ca9e9991f3",
      "type": "법인",
      "status": "IN_APPROVAL",
      "name": "SM6",
      "number": "43거 2485",
      "purpose": "출근",
      "logDateTime": "2024-11-15T09:00:00",
      "distance": 10,
      "starting": "다우기술",
      "destination": "모란역",
      "remarks": null
    },
    {
      "id": "5bff2f26-af7a-4695-a841-031538f27348",
      "type": "개인",
      "status": "APPROVED",
      "name": "SM6",
      "number": "353루 5016",
      "purpose": "출장",
      "logDateTime": "2024-10-30T09:00:00",
      "distance": 50,
      "starting": "다우기술",
      "destination": "부산역",
      "remarks": null
    }
  ];

  return HttpResponse.json({
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "data" : { 
      "list": [...respData.slice(0, offset)],
      "page" : {
        "page" : page,
        "offset" : offset,
        "total" : 3,
        "totalPage" : 1,
      }
    }
  });
}

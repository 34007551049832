import { HttpResponse } from 'msw';

export function resolveDocs() {
  return HttpResponse.json({
    "page" : {
      "page" : 0,
      "offset" : 5,
      "total" : 50,
      "totalPage" : 10,
      "lastPage" : false
    },
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 5887,
      "createdAt" : "2024-12-19T16:25:55.023+09:00",
      "updatedAt" : "2024-12-19T16:25:55.023+09:00",
      "folderId" : 11,
      "folderName" : "인사",
      "folderPath" : "생활백서 > 인사",
      "creatorName" : "다우기술 생활백서",
      "creatorPosition" : "",
      "creatorId" : 1687,
      "completeDate" : "2024-12-19T16:25:54.873+09:00",
      "title" : "취미동호회",
      "content" : " &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;-&nbsp;&#51064;&#50896;&#48320;&#44221; &#49888;&#52397;&#51008; &#49345;&#49884; &#44032;&#45733; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull;&nbsp;&#45796;&#50864;&#44592;&#49696; &#52712;&#48120;&#46041;&#54840;&#54924; <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &gt; '&#52509;&#47924; &#50836;&#52397;",
      "attachFileNames" : "취미동호회 지원금 신청서(2025).pptx",
      "docsYear" : 0,
      "version" : 26
    }, {
      "id" : 5884,
      "createdAt" : "2024-12-19T16:09:30.279+09:00",
      "updatedAt" : "2024-12-19T16:25:55.031+09:00",
      "folderId" : 11,
      "folderName" : "인사",
      "folderPath" : "생활백서 > 인사",
      "creatorName" : "다우기술 생활백서",
      "creatorPosition" : "",
      "creatorId" : 1687,
      "completeDate" : "2024-12-19T16:09:30.229+09:00",
      "title" : "취미동호회",
      "content" : " &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;-&nbsp;&#51064;&#50896;&#48320;&#44221; &#49888;&#52397;&#51008; &#49345;&#49884; &#44032;&#45733; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull;&nbsp;&#45796;&#50864;&#44592;&#49696; &#52712;&#48120;&#46041;&#54840;&#54924; <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &gt; '&#52509;&#47924; &#50836;&#52397;",
      "attachFileNames" : "취미동호회 지원금 신청서(2025).pptx",
      "docsYear" : 0,
      "version" : 25
    }, {
      "id" : 5605,
      "createdAt" : "2024-05-25T13:13:05.372+09:00",
      "updatedAt" : "2024-05-25T13:13:05.372+09:00",
      "folderId" : 11,
      "folderName" : "인사",
      "folderPath" : "생활백서 > 인사",
      "creatorName" : "다우기술 생활백서",
      "creatorPosition" : "",
      "creatorId" : 1687,
      "completeDate" : "2024-05-25T13:13:05.343+09:00",
      "title" : "교육신청(학습동호회)",
      "content" : "(&#54876;&#46041;&#44208;&#44284;&#48372;&#44256; &#51228;&#52636; &#51060;&#54980;) &#51068;&#44292; &#48152;&#50689;&#46108;&#45796;. 7. &#46041;&#54840;&#54924; <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&bull;&nbsp;&nbsp;&#54876;&#48156;&#54620; &#54617;&#49845;&#46041;&#54840;&#54924; &#54876;&#46041;&#51012;",
      "attachFileNames" : "학습동호회 가이드(2024).pptx",
      "docsYear" : 0,
      "version" : 9
    }, {
      "id" : 5604,
      "createdAt" : "2024-05-25T12:55:36.251+09:00",
      "updatedAt" : "2024-12-19T16:09:30.287+09:00",
      "folderId" : 11,
      "folderName" : "인사",
      "folderPath" : "생활백서 > 인사",
      "creatorName" : "다우기술 생활백서",
      "creatorPosition" : "",
      "creatorId" : 1687,
      "completeDate" : "2024-05-25T12:55:36.139+09:00",
      "title" : "취미동호회",
      "content" : " &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8251; &#45796;&#50864;&#44592;&#49696; &#52712;&#48120;&#46041;&#54840;&#54924; <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &gt; '&#52509;&#47924; &#50836;&#52397; &#49324;&#54637;' &#44172;&#49884;&#54032;&#51012;&nbsp;&#53685;&#54644; &#49888;&#52397; &rarr; &#49888;&#52397;&#51068; &#51061;&#50900;&#48512;&#53552; &#51648;&#50896;",
      "attachFileNames" : "취미동호회 지원금 신청서(2022).pptx",
      "docsYear" : 0,
      "version" : 24
    }, {
      "id" : 5516,
      "createdAt" : "2024-03-27T19:22:42.398+09:00",
      "updatedAt" : "2024-05-25T12:55:36.254+09:00",
      "folderId" : 11,
      "folderName" : "인사",
      "folderPath" : "생활백서 > 인사",
      "creatorName" : "다우기술 생활백서",
      "creatorPosition" : "",
      "creatorId" : 1687,
      "completeDate" : "2024-03-27T19:22:42.307+09:00",
      "title" : "취미동호회",
      "content" : " &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8251; &#45796;&#50864;&#44592;&#49696; &#52712;&#48120;&#46041;&#54840;&#54924; <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &gt; '&#52509;&#47924; &#50836;&#52397; &#49324;&#54637;' &#44172;&#49884;&#54032;&#51012;&nbsp;&#53685;&#54644; &#49888;&#52397; &rarr; &#49888;&#52397;&#51068; &#51061;&#50900;&#48512;&#53552; &#51648;&#50896;",
      "attachFileNames" : "취미동호회 지원금 신청서(2022).pptx",
      "docsYear" : 0,
      "version" : 23
    } ]
  });
}

import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveApproval: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));

  const respData = [ 
    {
      "id" : 853704,
      "isPublic" : "N",
      "formName" : "<strong class='txt_key'>휴가</strong>신청",
      "title" : "<strong class='txt_key'>휴가</strong>신청",
      "drafterId" : 5491,
      "drafterName" : "강봉수",
      "drafterDeptName" : "포털서비스개발팀",
      "draftedAt" : "2024-12-09T08:07:41.655+09:00",
      "completedAt" : "2024-12-09T09:09:57.420+09:00",
      "docStatus" : "INPROGRESS",
      "docType" : "DRAFT",
      "docBody" : "<strong class='txt_key'>휴가</strong>신청서 기안자 강봉수 소속 포털서비스개발팀 기안일 2024-12-09(월) 문서번호 groupwaredev-2024-01349 신청팀원 강봉수 책임 2024/12/09승인팀장 이재희 책임 2024/12/09수신팀원 박광호 책임 2024/12/09 * <strong class='txt_key'>휴가</strong> 종류 연차 * <strong class='txt_key'>휴가</strong> 기간 2024-12-09(월)~2024-12-09(월) 사용일수 : 1일 반차 여부 stahalftype( stadaytypeMFstadaytypeMAstadaytypeAFstadaytypeAA)endhalftype( enddaytype",
      "docNum" : "groupwaredev-2024-01349",
      "userIds" : "384",
      "attachFileCount" : 0
    }, 
    {
      "id" : 853667,
      "isPublic" : "N",
      "formName" : "<strong class='txt_key'>휴가</strong>신청",
      "title" : "<strong class='txt_key'>휴가</strong>신청",
      "drafterId" : 1165,
      "drafterName" : "정해운",
      "drafterDeptName" : "포털서비스개발팀",
      "draftedAt" : "2024-12-06T20:06:01.835+09:00",
      "completedAt" : "2024-12-09T09:10:38.725+09:00",
      "docStatus" : "COMPLETE",
      "docType" : "DRAFT",
      "docBody" : "<strong class='txt_key'>휴가</strong>신청서 기안자 정해운 소속 포털서비스개발팀 기안일 2024-12-06(금) 문서번호 groupwaredev-2024-01350 신청팀원 정해운 책임 2024/12/06승인팀장 이재희 책임 2024/12/06수신팀원 박광호 책임 2024/12/09 * <strong class='txt_key'>휴가</strong> 종류 연차 * <strong class='txt_key'>휴가</strong> 기간 2024-12-09(월)~2024-12-09(월) 사용일수 : 1일 반차 여부 시작일( stadaytypeMF오전後오후前stadaytypeAA)endhalftype( enddaytypeMFenddaytypeMAenddaytypeAFenddaytype",
      "docNum" : "groupwaredev-2024-01350",
      "userIds" : "384",
      "attachFileCount" : 0
    }, {
      "id" : 848620,
      "isPublic" : "N",
      "formName" : "<strong class='txt_key'>휴가</strong>신청",
      "title" : "<strong class='txt_key'>휴가</strong>신청",
      "drafterId" : 5491,
      "drafterName" : "강봉수",
      "drafterDeptName" : "포털서비스개발팀",
      "draftedAt" : "2024-11-26T06:59:52.697+09:00",
      "completedAt" : "2024-11-26T12:24:09.555+09:00",
      "docStatus" : "RETURN",
      "docType" : "DRAFT",
      "docBody" : "<strong class='txt_key'>휴가</strong>신청서 기안자 강봉수 소속 포털서비스개발팀 기안일 2024-11-26(화) 문서번호 groupwaredev-2024-01280 신청팀원 강봉수 책임 2024/11/26승인팀장 이재희 책임 2024/11/26수신팀원 박광호 책임 2024/11/26 * <strong class='txt_key'>휴가</strong> 종류 연차 * <strong class='txt_key'>휴가</strong> 기간 2024-11-26(화)~2024-11-26(화) 사용일수 : 1일 반차 여부 stahalftype( stadaytypeMFstadaytypeMAstadaytypeAFstadaytypeAA)endhalftype( enddaytype",
      "docNum" : "groupwaredev-2024-01280",
      "userIds" : "384",
      "attachFileCount" : 0
    }, 
    {
      "id" : 838887,
      "isPublic" : "N",
      "formName" : "<strong class='txt_key'>휴가</strong>신청",
      "title" : "<strong class='txt_key'>휴가</strong>신청",
      "drafterId" : 5491,
      "drafterName" : "강봉수",
      "drafterDeptName" : "포털서비스개발팀",
      "draftedAt" : "2024-10-29T06:42:36.009+09:00",
      "completedAt" : "2024-10-29T10:43:47.598+09:00",
      "docStatus" : "HOLD",
      "docType" : "DRAFT",
      "docBody" : "<strong class='txt_key'>휴가</strong>신청서 기안자 강봉수 소속 포털서비스개발팀 기안일 2024-10-29(화) 문서번호 groupwaredev-2024-01188 신청팀원 강봉수 책임 2024/10/29승인팀장 이재희 책임 2024/10/29수신팀원 박광호 책임 2024/10/29 * <strong class='txt_key'>휴가</strong> 종류 연차 * <strong class='txt_key'>휴가</strong> 기간 2024-10-29(화)~2024-10-29(화) 사용일수 : 1일 반차 여부 stahalftype( stadaytypeMFstadaytypeMAstadaytypeAFstadaytypeAA)endhalftype( enddaytype",
      "docNum" : "groupwaredev-2024-01188",
      "userIds" : "384",
      "attachFileCount" : 0
    }, 
    {
      "id" : 832259,
      "isPublic" : "N",
      "formName" : "<strong class='txt_key'>휴가</strong>신청",
      "title" : "<strong class='txt_key'>휴가</strong>신청",
      "drafterId" : 5491,
      "drafterName" : "강봉수",
      "drafterDeptName" : "포털서비스개발팀",
      "draftedAt" : "2024-10-08T06:50:01.272+09:00",
      "completedAt" : "2024-10-08T18:16:34.123+09:00",
      "docStatus" : "COMPLETE",
      "docType" : "DRAFT",
      "docBody" : "<strong class='txt_key'>휴가</strong>신청서 기안자 강봉수 소속 포털서비스개발팀 기안일 2024-10-08(화) 문서번호 groupwaredev-2024-01108 신청팀원 강봉수 책임 2024/10/08승인팀장 이재희 책임 2024/10/08수신팀원 박광호 책임 2024/10/08 * <strong class='txt_key'>휴가</strong> 종류 연차 * <strong class='txt_key'>휴가</strong> 기간 2024-10-08(화)~2024-10-08(화) 사용일수 : 1일 반차 여부 stahalftype( stadaytypeMFstadaytypeMAstadaytypeAFstadaytypeAA)endhalftype( enddaytype",
      "docNum" : "groupwaredev-2024-01108",
      "userIds" : "384",
      "attachFileCount" : 0
    }
  ];

  return HttpResponse.json({
    "page" : {
      "page" : page,
      "offset" : offset,
      "total" : 37,
      "totalPage" : 8,
      "lastPage" : false
    },
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [...respData]
  });
}

import { HttpResponse } from 'msw';

export function resolveWorks() {
  return HttpResponse.json({
  "message" : "OK",
  "code" : "200",
  "__go_checksum__" : true,
  "data" : {
    "appletSummaries" : [ {
      "id" : 6982,
      "name" : "업무용 차량 예약 관리",
      "thumbSmall" : "/thumb/attach/tiny/2018891-2393",
      "titleCid" : "_jc64snrp1",
      "fields" : [ {
        "cid" : "create_date",
        "label" : "등록일",
        "fieldType" : "create_date",
        "valueType" : "DATETIME",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "등록일",
          "hideLabel" : false,
          "code" : "create_date"
        }
      }, {
        "cid" : "_jc64snrp1",
        "label" : "사용 차량",
        "fieldType" : "select",
        "valueType" : "SELECT",
        "options" : [ {
          "value" : -999,
          "displayText" : "선택안함",
          "selected" : true
        }, {
          "value" : 2,
          "displayText" : "카니발(233하4089)",
          "selected" : false
        }, {
          "value" : 3,
          "displayText" : "사용불가_캐스퍼(191허9251)",
          "selected" : false
        }, {
          "value" : 4,
          "displayText" : "레이밴(223하4837)",
          "selected" : false
        } ],
        "multiple" : false,
        "properties" : {
          "label" : "사용 차량",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : false,
          "required" : true,
          "defaultValue" : "-999",
          "items" : [ {
            "value" : "-999",
            "displayText" : "선택안함",
            "selected" : true
          }, {
            "value" : "2",
            "displayText" : "카니발(233하4089)",
            "selected" : false
          }, {
            "value" : "3",
            "displayText" : "사용불가_캐스퍼(191허9251)",
            "selected" : false
          }, {
            "value" : "4",
            "displayText" : "레이밴(223하4837)",
            "selected" : false
          } ]
        }
      }, {
        "cid" : "_il0y0mzzn",
        "label" : "이용 사유",
        "fieldType" : "text",
        "valueType" : "STEXT",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "이용 사유",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : true,
          "duplicate" : false,
          "similar" : false,
          "defaultValue" : "",
          "minLength" : 0,
          "maxLength" : 100,
          "width" : 100,
          "widthUnit" : "percent",
          "format" : "all"
        }
      }, {
        "cid" : "creator",
        "label" : "사용자",
        "fieldType" : "creator",
        "valueType" : "USER",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "사용자",
          "hideLabel" : false,
          "code" : "creator"
        }
      }, {
        "cid" : "_ap85k2kgj",
        "label" : "소속/부서",
        "fieldType" : "org_dept",
        "valueType" : "DEPTS",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "소속/부서",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : false,
          "noLimit" : true,
          "useDefaultRegister" : true,
          "allowDeptCount" : "1",
          "depts" : [ ]
        }
      }, {
        "cid" : "_lcg468ddr",
        "label" : "사용 시작 일자/시간 ",
        "fieldType" : "datetime",
        "valueType" : "DATETIME",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "사용 시작 일자/시간 ",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : true,
          "defaultAsNow" : false,
          "defaultDate" : "",
          "defaultTime" : "",
          "code" : "datetime_1957",
          "useAlarm" : true,
          "alarms" : {
            "timers" : [ {
              "type" : "HOUR",
              "time" : "1"
            } ],
            "method" : "NOTIFICATION",
            "targets" : [ "ADMIN" ]
          }
        }
      }, {
        "cid" : "_vu3mwd7w7",
        "label" : "사용 종료 일자/시간",
        "fieldType" : "datetime",
        "valueType" : "DATETIME",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "사용 종료 일자/시간",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : true,
          "defaultAsNow" : false,
          "defaultDate" : "",
          "defaultTime" : "",
          "code" : "datetime_1949"
        }
      }, {
        "cid" : "_mwrqndgkr",
        "label" : "총 사용 예정 시간",
        "fieldType" : "formula",
        "valueType" : "NUMBER",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "총 사용 예정 시간",
          "hideLabel" : false,
          "guide" : "업무시간 이내 사용을 원칙으로 합니다. ",
          "guideAsTooltip" : false,
          "expressionType" : "time",
          "expression" : "datetime_1949-datetime_1957",
          "dateValueType" : "DATETIME",
          "width" : 250,
          "widthUnit" : "px",
          "unitText" : "",
          "fixType" : "postfix",
          "decimalPoints" : 2,
          "thousandComma" : true,
          "code" : "formula_5384"
        }
      }, {
        "cid" : "_es56atuz7",
        "label" : "요일구분",
        "fieldType" : "radio",
        "valueType" : "SELECT",
        "options" : [ {
          "value" : -999,
          "displayText" : "선택안함",
          "selected" : false
        }, {
          "value" : 0,
          "displayText" : "평일",
          "selected" : true
        }, {
          "value" : 1,
          "displayText" : "주말/휴일",
          "selected" : false
        } ],
        "multiple" : false,
        "properties" : {
          "label" : "요일구분",
          "hideLabel" : false,
          "guide" : "주말/휴일 예약 건의 경우 총무팀 협조 요청",
          "guideAsTooltip" : false,
          "required" : true,
          "defaultValue" : "0",
          "items" : [ {
            "value" : "-999",
            "displayText" : "선택안함",
            "selected" : false
          }, {
            "value" : "0",
            "displayText" : "평일",
            "selected" : true
          }, {
            "value" : "1",
            "displayText" : "주말/휴일",
            "selected" : false
          } ],
          "align" : "horizontal"
        }
      }, {
        "cid" : "_41tfsingl",
        "label" : "동승인원",
        "fieldType" : "select",
        "valueType" : "SELECT",
        "options" : [ {
          "value" : -999,
          "displayText" : "선택안함",
          "selected" : true
        }, {
          "value" : 0,
          "displayText" : "0명",
          "selected" : false
        }, {
          "value" : 4,
          "displayText" : "1명",
          "selected" : false
        }, {
          "value" : 1,
          "displayText" : "2명",
          "selected" : false
        }, {
          "value" : 2,
          "displayText" : "3명",
          "selected" : false
        }, {
          "value" : 3,
          "displayText" : "4명이상",
          "selected" : false
        } ],
        "multiple" : false,
        "properties" : {
          "label" : "동승인원",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : true,
          "defaultValue" : "-999",
          "items" : [ {
            "value" : "-999",
            "displayText" : "선택안함",
            "selected" : true
          }, {
            "value" : "0",
            "displayText" : "0명",
            "selected" : false
          }, {
            "value" : "4",
            "displayText" : "1명",
            "selected" : false
          }, {
            "value" : "1",
            "displayText" : "2명",
            "selected" : false
          }, {
            "value" : "2",
            "displayText" : "3명",
            "selected" : false
          }, {
            "value" : "3",
            "displayText" : "4명이상",
            "selected" : false
          } ]
        }
      }, {
        "cid" : "_d69zf45c2",
        "label" : "기타 요청사항",
        "fieldType" : "text",
        "valueType" : "STEXT",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "기타 요청사항",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : false,
          "duplicate" : false,
          "similar" : false,
          "defaultValue" : "",
          "minLength" : 0,
          "maxLength" : 100,
          "width" : 100,
          "widthUnit" : "percent",
          "format" : "all"
        }
      }, {
        "cid" : "update_date",
        "label" : "수정일",
        "fieldType" : "update_date",
        "valueType" : "DATETIME",
        "options" : [ ],
        "multiple" : false
      }, {
        "cid" : "updater",
        "label" : "수정자",
        "fieldType" : "updater",
        "valueType" : "USER",
        "options" : [ ],
        "multiple" : false
      }, {
        "cid" : "status",
        "label" : "상태",
        "fieldType" : "status",
        "valueType" : "SELECT",
        "options" : [ {
          "value" : 22641,
          "displayText" : "예약취소",
          "selected" : false
        }, {
          "value" : 22639,
          "displayText" : "반납완료",
          "selected" : false
        }, {
          "value" : 22637,
          "displayText" : "예약신청",
          "selected" : false
        }, {
          "value" : 22638,
          "displayText" : "예약확정",
          "selected" : false
        }, {
          "value" : 22640,
          "displayText" : "사용 중",
          "selected" : false
        }, {
          "value" : 22643,
          "displayText" : "수리중",
          "selected" : false
        }, {
          "value" : 22663,
          "displayText" : "예약불가",
          "selected" : false
        } ],
        "multiple" : false
      } ]
    }, {
      "id" : 72,
      "name" : "사내 IT 지원 요청",
      "thumbSmall" : "/thumb/attach/tiny/159282-5598",
      "titleCid" : "_jqg91l361",
      "fields" : [ {
        "cid" : "_jqg91l361",
        "label" : "요청 제목",
        "fieldType" : "text",
        "valueType" : "STEXT",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "요청 제목",
          "hideLabel" : false,
          "guide" : "요청 내용을 간략하게 입력하세요.",
          "guideAsTooltip" : true,
          "required" : true,
          "defaultValue" : "",
          "minLength" : 0,
          "maxLength" : 100,
          "width" : "70",
          "widthUnit" : "percent",
          "format" : "all"
        }
      }, {
        "cid" : "_ppvcxxbyg",
        "label" : "요청 상세 내용",
        "fieldType" : "textarea",
        "valueType" : "TEXT",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "요청 상세 내용",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : false,
          "defaultValue" : "",
          "width" : 100,
          "widthUnit" : "percent",
          "rows" : "4"
        }
      }, {
        "cid" : "_azg59yzk8",
        "label" : "요청자",
        "fieldType" : "org",
        "valueType" : "USERS",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "요청자",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : true,
          "noLimit" : true,
          "useDefaultRegister" : true,
          "allowMemberCount" : "1",
          "members" : [ ]
        }
      }, {
        "cid" : "_zk3ma1ik6",
        "label" : "요청부서",
        "fieldType" : "org_dept",
        "valueType" : "DEPTS",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "요청부서",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : false,
          "noLimit" : true,
          "useDefaultRegister" : false,
          "allowDeptCount" : "1",
          "depts" : [ ]
        }
      }, {
        "cid" : "_r6slasuus",
        "label" : "연락처",
        "fieldType" : "text",
        "valueType" : "STEXT",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "연락처",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : false,
          "defaultValue" : "",
          "minLength" : 0,
          "maxLength" : 100,
          "width" : "50",
          "widthUnit" : "percent",
          "format" : "all"
        }
      }, {
        "cid" : "_4vo8yeq2o",
        "label" : "요청 구분",
        "fieldType" : "select",
        "valueType" : "SELECT",
        "options" : [ {
          "value" : 0,
          "displayText" : "ERP 관련",
          "selected" : true
        }, {
          "value" : 1,
          "displayText" : "Portal 계정 관련",
          "selected" : false
        }, {
          "value" : 2,
          "displayText" : "내선 전화 관련",
          "selected" : false
        }, {
          "value" : 3,
          "displayText" : "IP 및 네트워크 관련",
          "selected" : false
        }, {
          "value" : 4,
          "displayText" : "NAC 관련",
          "selected" : false
        }, {
          "value" : 5,
          "displayText" : "PC 및 소모품 관련",
          "selected" : false
        }, {
          "value" : 6,
          "displayText" : "기타",
          "selected" : false
        } ],
        "multiple" : false,
        "properties" : {
          "label" : "요청 구분",
          "hideLabel" : false,
          "guide" : "",
          "guideAsTooltip" : true,
          "required" : false,
          "items" : [ {
            "value" : "0",
            "displayText" : "ERP 관련",
            "selected" : true
          }, {
            "value" : "1",
            "displayText" : "Portal 계정 관련",
            "selected" : false
          }, {
            "value" : "2",
            "displayText" : "내선 전화 관련",
            "selected" : false
          }, {
            "value" : "3",
            "displayText" : "IP 및 네트워크 관련",
            "selected" : false
          }, {
            "value" : "4",
            "displayText" : "NAC 관련",
            "selected" : false
          }, {
            "value" : "5",
            "displayText" : "PC 및 소모품 관련",
            "selected" : false
          }, {
            "value" : "6",
            "displayText" : "기타",
            "selected" : false
          } ],
          "itmes" : [ {
            "value" : "0",
            "displayText" : "계정 관련",
            "selected" : true
          }, {
            "value" : "1",
            "displayText" : "장비 관련",
            "selected" : false
          }, {
            "value" : "2",
            "displayText" : "CRM 관련",
            "selected" : false
          }, {
            "value" : 3,
            "displayText" : "ERP 관련",
            "selected" : false
          }, {
            "value" : 4,
            "displayText" : "인사시스템 관련",
            "selected" : false
          }, {
            "value" : 5,
            "displayText" : "다우포털 관련",
            "selected" : false
          }, {
            "value" : 6,
            "displayText" : "기타",
            "selected" : false
          } ]
        }
      }, {
        "cid" : "_6mlm8ir2h",
        "label" : "작업 담당자",
        "fieldType" : "org",
        "valueType" : "USERS",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "작업 담당자",
          "hideLabel" : false,
          "guide" : "처리 담당자는 인프라운영팀에서 기록합니다. ",
          "guideAsTooltip" : true,
          "required" : false,
          "noLimit" : true,
          "useDefaultRegister" : false,
          "allowMemberCount" : "1",
          "members" : [ ]
        }
      }, {
        "cid" : "_9sg7ff1mt",
        "label" : "승인 담당자",
        "fieldType" : "org",
        "valueType" : "USERS",
        "options" : [ ],
        "multiple" : false,
        "properties" : {
          "label" : "승인 담당자",
          "hideLabel" : false,
          "guide" : "처리 담당자는 인프라운영팀에서 기록합니다. ",
          "guideAsTooltip" : true,
          "required" : false,
          "noLimit" : true,
          "useDefaultRegister" : false,
          "allowMemberCount" : "1",
          "members" : [ ],
          "code" : ""
        }
      }, {
        "cid" : "create_date",
        "label" : "등록일",
        "fieldType" : "create_date",
        "valueType" : "DATETIME",
        "options" : [ ],
        "multiple" : false
      }, {
        "cid" : "creator",
        "label" : "등록자",
        "fieldType" : "creator",
        "valueType" : "USER",
        "options" : [ ],
        "multiple" : false
      }, {
        "cid" : "update_date",
        "label" : "수정일",
        "fieldType" : "update_date",
        "valueType" : "DATETIME",
        "options" : [ ],
        "multiple" : false
      }, {
        "cid" : "updater",
        "label" : "수정자",
        "fieldType" : "updater",
        "valueType" : "USER",
        "options" : [ ],
        "multiple" : false
      }, {
        "cid" : "status",
        "label" : "상태",
        "fieldType" : "status",
        "valueType" : "SELECT",
        "options" : [ {
          "value" : 228,
          "displayText" : "▶ 대기  ",
          "selected" : false
        }, {
          "value" : 229,
          "displayText" : "▶ 접수완료(진행)",
          "selected" : false
        }, {
          "value" : 230,
          "displayText" : "처리완료 ",
          "selected" : false
        }, {
          "value" : 240,
          "displayText" : "처리완료(반려)",
          "selected" : false
        } ],
        "multiple" : false
      } ]
    } ],
    "docs" : {
      "pageInfo" : {
        "page" : 0,
        "offset" : 5,
        "total" : 2263,
        "totalPage" : 453,
        "lastPage" : false
      },
      "content" : [ {
        "id" : 2282973,
        "appletId" : 6982,
        "status" : {
          "id" : 22638,
          "name" : "예약확정",
          "start" : false,
          "doing" : true,
          "end" : false,
          "color" : "10"
        },
        "values" : {
          "_ap85k2kgj" : [ {
            "code" : "0000000335",
            "useReception" : true,
            "additions" : {
              "koName" : "인사팀"
            },
            "memberCount" : 10,
            "emailId" : "hrteam",
            "groupDepth" : 0,
            "deletedDept" : false,
            "sortFlag" : true,
            "parentId" : 740,
            "deptAlias" : "hrteam",
            "companyId" : 4,
            "depth" : 0,
            "parentCode" : "O000000119",
            "sortOrder" : 3,
            "useReference" : false,
            "name" : "인사팀",
            "id" : 26,
            "email" : "hrteam@daou.co.kr",
            "childrenCount" : 0
          } ],
          "creator" : {
            "deptName" : "인사팀",
            "thumbnail" : "/thumb/user/small/3617303-7938",
            "loginId" : "hyuna0060",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 26,
            "fullName" : "주현아 사원",
            "employeeNumber" : "4546",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "hyuna0060@daou.co.kr",
            "name" : "주현아",
            "id" : 5829,
            "position" : "사원",
            "email" : "hyuna0060@daou.co.kr",
            "status" : "ONLINE"
          },
          "_jc64snrp1" : 4,
          "_es56atuz7" : 0,
          "_41tfsingl" : 0,
          "_mwrqndgkr" : 3600000,
          "_il0y0mzzn" : "우체국업무",
          "_lcg468ddr" : "2025-01-03T14:00:00.000+09:00",
          "create_date" : "2025-01-03T11:41:35.278+09:00",
          "_vu3mwd7w7" : "2025-01-03T15:00:00.000+09:00",
          "update_date" : "2025-01-03T11:41:38.008+09:00",
          "updater" : {
            "deptName" : "인사팀",
            "thumbnail" : "/thumb/user/small/3617303-7938",
            "loginId" : "hyuna0060",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 26,
            "fullName" : "주현아 사원",
            "employeeNumber" : "4546",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "hyuna0060@daou.co.kr",
            "name" : "주현아",
            "id" : 5829,
            "position" : "사원",
            "email" : "hyuna0060@daou.co.kr",
            "status" : "ONLINE"
          }
        },
        "privateFlag" : false
      }, {
        "id" : 2282871,
        "appletId" : 72,
        "status" : {
          "id" : 230,
          "name" : "처리완료 ",
          "start" : false,
          "doing" : false,
          "end" : true,
          "color" : "0"
        },
        "values" : {
          "_9sg7ff1mt" : [ ],
          "creator" : {
            "deptName" : "재경팀",
            "thumbnail" : "/thumb/user/small/3872898-140432",
            "loginId" : "soojin89",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 27,
            "fullName" : "반수진 사원",
            "employeeNumber" : "4583",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "soojin89@daou.co.kr",
            "name" : "반수진",
            "id" : 5924,
            "position" : "사원",
            "email" : "soojin89@daou.co.kr",
            "status" : "ONLINE"
          },
          "_zk3ma1ik6" : [ {
            "code" : "0000000075",
            "useReception" : true,
            "additions" : {
              "koName" : "재경팀"
            },
            "memberCount" : 10,
            "emailId" : "financeadmin",
            "groupDepth" : 0,
            "deletedDept" : false,
            "sortFlag" : true,
            "parentId" : 741,
            "deptAlias" : "financeadmin",
            "companyId" : 4,
            "depth" : 0,
            "parentCode" : "O000000120",
            "sortOrder" : 3,
            "useReference" : false,
            "name" : "재경팀",
            "id" : 27,
            "email" : "financeadmin@daou.co.kr",
            "childrenCount" : 0
          } ],
          "_4vo8yeq2o" : 3,
          "_6mlm8ir2h" : [ ],
          "_r6slasuus" : "07087071538",
          "_azg59yzk8" : [ {
            "deptName" : "재경팀",
            "thumbnail" : "/thumb/user/small/3872898-140432",
            "loginId" : "soojin89",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 27,
            "fullName" : "반수진 사원",
            "employeeNumber" : "4583",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "soojin89@daou.co.kr",
            "name" : "반수진",
            "id" : 5924,
            "position" : "사원",
            "email" : "soojin89@daou.co.kr",
            "status" : "ONLINE"
          } ],
          "create_date" : "2025-01-03T10:05:19.767+09:00",
          "_jqg91l361" : "신규입사자 유선IP 요청",
          "update_date" : "2025-01-03T11:13:59.166+09:00",
          "updater" : {
            "deptName" : "사업지원팀",
            "thumbnail" : "/thumb/user/small/2789320-88497",
            "loginId" : "sjlim",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 558,
            "fullName" : "임승주 사원",
            "employeeNumber" : "4537",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "sjlim@daou.co.kr",
            "name" : "임승주",
            "id" : 5456,
            "position" : "사원",
            "email" : "sjlim@daou.co.kr",
            "status" : "ONLINE"
          }
        },
        "privateFlag" : false
      }, {
        "id" : 2282638,
        "appletId" : 72,
        "status" : {
          "id" : 228,
          "name" : "▶ 대기  ",
          "start" : true,
          "doing" : false,
          "end" : false,
          "color" : "0"
        },
        "values" : {
          "_9sg7ff1mt" : [ ],
          "creator" : {
            "deptName" : "경영기술개발팀",
            "thumbnail" : "/thumb/user/small/490831-82088",
            "loginId" : "cslee",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 859,
            "fullName" : "이창수 수석",
            "employeeNumber" : "1476",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "cslee@daou.co.kr",
            "name" : "이창수",
            "id" : 387,
            "position" : "수석",
            "email" : "cslee@daou.co.kr",
            "status" : "ONLINE"
          },
          "_zk3ma1ik6" : [ {
            "code" : "O000000158",
            "useReception" : true,
            "additions" : {
              "koName" : "경영기술개발팀"
            },
            "memberCount" : 7,
            "emailId" : "sysmaning2",
            "groupDepth" : 0,
            "deletedDept" : false,
            "sortFlag" : true,
            "parentId" : 880,
            "deptAlias" : "sysmaning2",
            "companyId" : 4,
            "depth" : 0,
            "parentCode" : "O000000159",
            "sortOrder" : 1,
            "useReference" : false,
            "name" : "경영기술개발팀",
            "id" : 859,
            "email" : "sysmaning2@daou.co.kr",
            "childrenCount" : 0
          } ],
          "_4vo8yeq2o" : 1,
          "_6mlm8ir2h" : [ ],
          "_azg59yzk8" : [ {
            "deptName" : "경영기술개발팀",
            "thumbnail" : "/thumb/user/small/490831-82088",
            "loginId" : "cslee",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 859,
            "fullName" : "이창수 수석",
            "employeeNumber" : "1476",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "cslee@daou.co.kr",
            "name" : "이창수",
            "id" : 387,
            "position" : "수석",
            "email" : "cslee@daou.co.kr",
            "status" : "ONLINE"
          } ],
          "create_date" : "2025-01-03T09:33:38.507+09:00",
          "_jqg91l361" : "[업무망 그룹웨어] 조직도 변경 요청",
          "update_date" : "2025-01-03T09:36:08.984+09:00",
          "updater" : {
            "deptName" : "경영기술개발팀",
            "thumbnail" : "/thumb/user/small/490831-82088",
            "loginId" : "cslee",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 859,
            "fullName" : "이창수 수석",
            "employeeNumber" : "1476",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "cslee@daou.co.kr",
            "name" : "이창수",
            "id" : 387,
            "position" : "수석",
            "email" : "cslee@daou.co.kr",
            "status" : "ONLINE"
          }
        },
        "privateFlag" : false
      }, {
        "id" : 2282578,
        "appletId" : 72,
        "status" : {
          "id" : 230,
          "name" : "처리완료 ",
          "start" : false,
          "doing" : false,
          "end" : true,
          "color" : "0"
        },
        "values" : {
          "_9sg7ff1mt" : [ ],
          "creator" : {
            "deptName" : "쿠팝사업팀",
            "thumbnail" : "/thumb/user/small/2572478-228906",
            "loginId" : "junsup0511",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 750,
            "fullName" : "정준섭 사원",
            "employeeNumber" : "4290",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "junsup0511@daou.co.kr",
            "name" : "정준섭",
            "id" : 5348,
            "position" : "사원",
            "email" : "junsup0511@daou.co.kr",
            "status" : "ONLINE"
          },
          "_zk3ma1ik6" : [ {
            "code" : "O000000118",
            "useReception" : true,
            "additions" : {
              "koName" : "쿠팝사업팀"
            },
            "memberCount" : 5,
            "emailId" : "comm2",
            "groupDepth" : 0,
            "deletedDept" : false,
            "sortFlag" : true,
            "parentId" : 911,
            "deptAlias" : "comm2",
            "companyId" : 4,
            "depth" : 0,
            "parentCode" : "O000000163",
            "sortOrder" : 4,
            "useReference" : false,
            "name" : "쿠팝사업팀",
            "id" : 750,
            "email" : "comm2@daou.co.kr",
            "childrenCount" : 0
          } ],
          "_4vo8yeq2o" : 3,
          "_6mlm8ir2h" : [ ],
          "_r6slasuus" : "07087071224",
          "_azg59yzk8" : [ {
            "deptName" : "쿠팝사업팀",
            "thumbnail" : "/thumb/user/small/2572478-228906",
            "loginId" : "junsup0511",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 750,
            "fullName" : "정준섭 사원",
            "employeeNumber" : "4290",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "junsup0511@daou.co.kr",
            "name" : "정준섭",
            "id" : 5348,
            "position" : "사원",
            "email" : "junsup0511@daou.co.kr",
            "status" : "ONLINE"
          } ],
          "create_date" : "2025-01-03T08:50:00.872+09:00",
          "_jqg91l361" : "신규 입사자유선 IP 요청",
          "update_date" : "2025-01-03T11:12:56.426+09:00",
          "updater" : {
            "deptName" : "사업지원팀",
            "thumbnail" : "/thumb/user/small/2789320-88497",
            "loginId" : "sjlim",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 558,
            "fullName" : "임승주 사원",
            "employeeNumber" : "4537",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "sjlim@daou.co.kr",
            "name" : "임승주",
            "id" : 5456,
            "position" : "사원",
            "email" : "sjlim@daou.co.kr",
            "status" : "ONLINE"
          }
        },
        "privateFlag" : false
      }, {
        "id" : 2282420,
        "appletId" : 72,
        "status" : {
          "id" : 228,
          "name" : "▶ 대기  ",
          "start" : true,
          "doing" : false,
          "end" : false,
          "color" : "0"
        },
        "values" : {
          "_9sg7ff1mt" : [ ],
          "creator" : {
            "deptName" : "마케팅운영팀",
            "thumbnail" : "/thumb/user/small/498763-461705",
            "loginId" : "inocke",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 913,
            "fullName" : "정연곤 책임",
            "employeeNumber" : "2154",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "inocke@daou.co.kr",
            "name" : "정연곤",
            "id" : 417,
            "position" : "책임",
            "email" : "inocke@daou.co.kr",
            "status" : "ONLINE"
          },
          "_zk3ma1ik6" : [ {
            "code" : "O000000165",
            "useReception" : true,
            "additions" : {
              "koName" : "마케팅운영팀"
            },
            "memberCount" : 26,
            "emailId" : "message3",
            "groupDepth" : 0,
            "deletedDept" : false,
            "sortFlag" : true,
            "parentId" : 662,
            "deptAlias" : "message3",
            "companyId" : 4,
            "depth" : 0,
            "parentCode" : "O000000111",
            "sortOrder" : 1,
            "useReference" : false,
            "name" : "마케팅운영팀",
            "id" : 913,
            "email" : "message3@daou.co.kr",
            "childrenCount" : 0
          } ],
          "_4vo8yeq2o" : 1,
          "_6mlm8ir2h" : [ ],
          "_r6slasuus" : "07087071363",
          "_azg59yzk8" : [ {
            "deptName" : "마케팅운영팀",
            "thumbnail" : "/thumb/user/small/498763-461705",
            "loginId" : "inocke",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 913,
            "fullName" : "정연곤 책임",
            "employeeNumber" : "2154",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "inocke@daou.co.kr",
            "name" : "정연곤",
            "id" : 417,
            "position" : "책임",
            "email" : "inocke@daou.co.kr",
            "status" : "ONLINE"
          } ],
          "create_date" : "2025-01-02T16:02:28.357+09:00",
          "_jqg91l361" : "[업무망 그룹웨어] 신규 계정 생성 요청",
          "update_date" : "2025-01-02T16:02:28.357+09:00",
          "updater" : {
            "deptName" : "마케팅운영팀",
            "thumbnail" : "/thumb/user/small/498763-461705",
            "loginId" : "inocke",
            "manager" : false,
            "companyName" : "(주)다우기술",
            "moderator" : false,
            "deptId" : 913,
            "fullName" : "정연곤 책임",
            "employeeNumber" : "2154",
            "master" : false,
            "companyId" : 4,
            "originalEmail" : "inocke@daou.co.kr",
            "name" : "정연곤",
            "id" : 417,
            "position" : "책임",
            "email" : "inocke@daou.co.kr",
            "status" : "ONLINE"
          }
        },
        "privateFlag" : false
      } ]
    }
  }
});
}

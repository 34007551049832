import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveBoard: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));
  const respData = [
    {
      "id" : 93475,
      "createdAt" : "2024-12-19T11:18:13.848+09:00",
      "updatedAt" : "2024-12-19T11:18:13.849+09:00",
      "userId" : 5679,
      "writer" : {
        "id" : 5679,
        "name" : "윤주경",
        "positionName" : "사원",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/3247990-2451"
      },
      "boardId" : 4,
      "boardName" : "사내활동",
      "postHeaderId" : 8,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "[비즈웹개발팀] 2024년 4분기 팀 세미나",
      "content" : "1. &#51068;&#49884; : 12/17&nbsp;(&#54868;) 13:10 ~ 15:00 2. &#51109;&#49548; : &#51648;&#54616; 2&#52789;&nbsp;&#50724;&#46356;&#53664;&#47532;&#50880; &#54848; 3. &#51452;&#51228; : &#50648;&#46972;&#49828;&#54001; &#49436;&#52824;&#47484; &#51060;&#50857;&#54620; AI <strong class='txt_key'>&#44160;&#49353;</strong>",
      "attachFileNames" : "대충 잘 찾을 수 있을까.pdf",
      "hiddenPost" : false
    }, 
    {
      "id" : 92624,
      "createdAt" : "2024-11-01T10:00:27.359+09:00",
      "updatedAt" : "2024-11-01T10:00:27.364+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "11월 01일  다우 뉴스피드",
      "content" : " &#46020;&#51204;&#54620;&#45796;'&hellip;&#50724;&#54536;AI '&#52311;GPT <strong class='txt_key'>&#44160;&#49353;</strong>' &#44592;&#45733; &#52636;&#49884;_JTBC &#45684;&#49828; &#44397;&#45236; &#50976;&#47581; AI &#49828;&#53440;&#53944;&#50629; &lsquo;&#47420;&#53948;&rsquo;, &#50900;&#44036; &#51060;&#50857;&#51088; 500&#47564;&#47749; &#46028;&#54028;",
      "hiddenPost" : false
    }, 
    {
      "id" : 92386,
      "createdAt" : "2024-10-18T09:58:53.299+09:00",
      "updatedAt" : "2024-10-18T09:58:53.304+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "10월 18일  다우 뉴스피드",
      "content" : "&rsquo;_&#49436;&#50872;&#49888;&#47928; &quot;&#52572;&#54788;&#49437; &#49885;&#45817; &#50696;&#50557;&#54624;&#47000;&#50836;?&quot; &#45236; &#47560;&#51020;&#46020; <strong class='txt_key'>&#44160;&#49353;&#54620;</strong> &#49548;&#47492;&#46027;&#45716; AI_&#51473;&#50521;&#51068;&#48372; &#54620;&#44397;, &#51064;&#53552;&#45367; &#51088;&#50976;",
      "hiddenPost" : false
    }, 
    {
      "id" : 92216,
      "createdAt" : "2024-10-10T17:56:59.755+09:00",
      "updatedAt" : "2024-10-10T17:56:59.756+09:00",
      "userId" : 3685,
      "writer" : {
        "id" : 3685,
        "name" : "서기현",
        "positionName" : "선임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/628630-226980"
      },
      "boardId" : 1,
      "boardName" : "사내공지",
      "postHeaderId" : 179,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "2024-4분기 무선 네트워크 패스워드 변경작업 안내",
      "content" : " &#50672;&#44208;&#47196; <strong class='txt_key'>&#44160;&#49353;&#54644;&#49436;</strong> &#50672;&#44208;) daou_mobile !public24 &#44277;&#44060; &#50752;&#51060;&#54028;&#51060; daou_guest #online56 &#44277;&#44060; &#50752;&#51060;&#54028;&#51060; &#51060; &#50808;&nbsp;&#47924;&#49440; &#45348;&#53944;&#50892;&#53356;&#45716;",
      "hiddenPost" : false
    }, 
    {
      "id" : 92184,
      "createdAt" : "2024-10-08T10:02:57.715+09:00",
      "updatedAt" : "2024-10-08T10:02:57.720+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "10월 08일  다우 뉴스피드",
      "content" : "?_&#50500;&#49884;&#50500;&#53804;&#45936;&#51060; &#49340;&#49457;&#54868;&#51116;, &#44552;&#50997;IT &#50504;&#51221;&#49457; &#50948;&#54644; '&#53580;&#49828;&#53944; &#51088;&#46041;&#54868;' &#49556;&#47336;&#49496; &#46020;&#51077;_&#51204;&#51088;&#49888;&#47928; <strong class='txt_key'>&#44160;&#49353;&#44305;&#44256;</strong> &#49884;&#51109;",
      "hiddenPost" : false
    }, 
    {
      "id" : 91778,
      "createdAt" : "2024-09-09T16:32:31.410+09:00",
      "updatedAt" : "2024-09-09T16:32:37.945+09:00",
      "userId" : 3784,
      "writer" : {
        "id" : 3784,
        "name" : "윤승욱",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/648392-693139"
      },
      "boardId" : 460,
      "boardName" : "DaouOffice 업데이트 알림",
      "postHeaderId" : 219,
      "boardType" : "CLASSIC",
      "ownerName" : "플랫폼기획1팀",
      "title" : "3.6.5.0",
      "content" : " &#51204;&#49324;&#44172;&#49884;&#54032;&#51060; &#44536;&#45824;&#47196; &#45432;&#52636;&#46104;&#45716; &#51060;&#49800; &#49888;&#44508;&#44060;&#48156; [GO-45570] - &#53216;&#54045; &#48708;&#51592; &#50672;&#46041; &#44592;&#45733;&#44060;&#49440; [GO-45172] - companyId&#48324; <strong class='txt_key'>&#44160;&#49353;&#45936;&#51060;&#53552;</strong>",
      "comments" : "메일 데몬 3.0.2.2\n",
      "hiddenPost" : false
    }, 
    {
      "id" : 91257,
      "createdAt" : "2024-08-13T10:08:36.870+09:00",
      "updatedAt" : "2024-08-13T10:08:36.875+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "08월 13일  다우 뉴스피드",
      "content" : " &#51208;&#44048;_&#54620;&#44397;&#44221;&#51228; &#44396;&#44544;&middot;MS, &#44397;&#45236; <strong class='txt_key'>&#50937;&#44160;&#49353;</strong> &#51216;&#50976;&#50984; 40% &#46028;&#54028;&hellip; &#45348;&#51060;&#48260;&middot;&#45796;&#51020; &#54616;&#46973;&#49464;_&#51312;&#49440;&#48708;&#51592; 4000&#47564;&#47749; &#50420;",
      "hiddenPost" : false
    }, 
    {
      "id" : 91214,
      "createdAt" : "2024-08-09T10:01:46.559+09:00",
      "updatedAt" : "2024-08-09T10:01:46.564+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "08월 09일  다우 뉴스피드",
      "content" : " &#45348;&#51060;&#48260;, 2&#48516;&#44592; &#50689;&#50629;&#51061; 4727&#50613;&hellip;&quot;<strong class='txt_key'>&#44160;&#49353;</strong>&middot;&#52964;&#47672;&#49828;&middot;&#54592;&#53580;&#53356; &#47784;&#46160; &#49457;&#51109;&quot;_&#45684;&#49884;&#49828; &#54592;&#53580;&#53356; &#47564;&#45212; &#54200;&#51032;&#51216;, GS25",
      "hiddenPost" : false
    }, 
    {
      "id" : 90967,
      "createdAt" : "2024-07-26T16:53:45.979+09:00",
      "updatedAt" : "2024-08-22T08:38:00.706+09:00",
      "userId" : 702,
      "writer" : {
        "id" : 702,
        "name" : "김민지",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/510118-627151"
      },
      "boardId" : 6,
      "boardName" : "사내제안",
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "RE : 전자결재 진행 시 '제목' 입력란 추가 제안",
      "content" : " &nbsp; - &#51221;&#54869;&#54620; &#47928;&#49436; &#54869;&#51064;&#51012; &#50948;&#54644;, &#47928;&#49436; &#51221;&#48372;&#50640; &#45824;&#54620; <strong class='txt_key'>&#44160;&#49353;</strong> &#46608;&#45716; &#49345;&#49464;&#54168;&#51060;&#51648;&#50640; &#51652;&#51077;&#54644;&#50556; &#54616;&#45716; &#48264;&#44144;&#47196;&#50880;",
      "comments" : "전자결재에 제목 추가 된 후, 아주 편리하게 사용하고 있습니다. \uD83D\uDC4F\uD83D\uDC4F\n아이디어 제안해주신 승훈선임님 감사합니다.",
      "hiddenPost" : false
    }, 
    {
      "id" : 90952,
      "createdAt" : "2024-07-26T09:57:50.661+09:00",
      "updatedAt" : "2024-07-26T09:57:50.672+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "07월 26일  다우 뉴스피드",
      "content" : "&quot;&hellip;<strong class='txt_key'>&#44160;&#49353;</strong> &#50644;&#51652; '&#49436;&#52824;GPT' &#48156;&#54364;_&#50672;&#54633;&#45684;&#49828; BC&#52852;&#46300;, &#54620;&#44397; &#44552;&#50997; &#47582;&#52644;&#54805; AI &#44060;&#48156;&hellip;&#47924;&#49345; &#44277;&#44060;_&#50672;&#54633;&#45684;&#49828; AI&#44032; &#50500;&#51060;&#46028;",
      "hiddenPost" : false
    }, 
    {
      "id" : 90685,
      "createdAt" : "2024-07-12T09:59:46.025+09:00",
      "updatedAt" : "2024-07-12T09:59:46.037+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "07월 12일  다우 뉴스피드",
      "content" : " &#47566;&#51060; <strong class='txt_key'>&#44160;&#49353;</strong>_&#51312;&#51060;&#45684;&#49828;24 &#50976;&#47196;2024&#50640; &#46321;&#51109;&#54620; &lsquo;&#46497;&#48374;&#51060;&rsquo;&hellip;&#49688;&#52636;&#46020; &#44256;&#44277;&#54665;&#51652;_&#45936;&#51068;&#47532;&#50504; &ldquo;&#52840;&#49688;",
      "hiddenPost" : false
    }, 
    {
      "id" : 90514,
      "createdAt" : "2024-07-04T11:26:59.789+09:00",
      "updatedAt" : "2024-08-22T08:39:24.665+09:00",
      "userId" : 5748,
      "writer" : {
        "id" : 5748,
        "name" : "한승훈",
        "positionName" : "선임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/3382232-8282"
      },
      "boardId" : 6,
      "boardName" : "사내제안",
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "전자결재 진행 시 '제목' 입력란 추가 제안",
      "content" : " &#51221;&#48372;&#50640; &#45824;&#54620; <strong class='txt_key'>&#44160;&#49353;</strong> &#46608;&#45716; &#49345;&#49464;&#54168;&#51060;&#51648;&#50640; &#51652;&#51077;&#54644;&#50556; &#54616;&#45716; &#48264;&#44144;&#47196;&#50880; &nbsp; &nbsp; 2. &#47785;&#51201; - &#44592;&#50504;&#47928;&#49436;&#54632;",
      "comments" : "전자결재에 제목 추가 된 후, 아주 편리하게 사용하고 있습니다. \uD83D\uDC4F\uD83D\uDC4F\n아이디어 제안해주신 승훈선임님 감사합니다.",
      "hiddenPost" : false
    }, 
    {
      "id" : 90446,
      "createdAt" : "2024-07-02T10:37:47.937+09:00",
      "updatedAt" : "2024-10-10T17:55:54.583+09:00",
      "userId" : 3685,
      "writer" : {
        "id" : 3685,
        "name" : "서기현",
        "positionName" : "선임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/628630-226980"
      },
      "boardId" : 1,
      "boardName" : "사내공지",
      "postHeaderId" : 179,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "2024-3분기 무선 네트워크 패스워드 변경작업 안내",
      "content" : " &#50672;&#44208;&#47196; <strong class='txt_key'>&#44160;&#49353;&#54644;&#49436;</strong> &#50672;&#44208;) daou_mobile ********* &#44277;&#44060; &#50752;&#51060;&#54028;&#51060; daou_guest ********* &#44277;&#44060; &#50752;&#51060;&#54028;&#51060; &#51060; &#50808;&nbsp;&#47924;&#49440; &#45348;&#53944;&#50892;&#53356;&#45716;",
      "hiddenPost" : false
    }, 
    {
      "id" : 90080,
      "createdAt" : "2024-06-14T09:59:37.483+09:00",
      "updatedAt" : "2024-06-14T09:59:37.503+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "06월 14일  다우 뉴스피드",
      "content" : " <strong class='txt_key'>&#44160;&#49353;</strong> &#49828;&#53440;&#53944;&#50629;&#50640; 1&#52380;&#47564; &#45804;&#47084; &#53804;&#51088;_&#50672;&#54633;&#45684;&#49828; &#44053;&#45224;&#44396;, &#44144;&#51452;&#51088; &#50864;&#49440; &#51452;&#52264;&#44396;&#50669;&#50640; 'AI &#45800;&#49549; &#49884;&#49828;&#53596;' &#50868;&#50689;",
      "hiddenPost" : false
    }, 
    {
      "id" : 89612,
      "createdAt" : "2024-05-21T10:00:25.806+09:00",
      "updatedAt" : "2024-05-21T10:00:25.816+09:00",
      "userId" : 3131,
      "writer" : {
        "id" : 3131,
        "name" : "다우기술",
        "positionName" : "",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2111571-8041"
      },
      "boardId" : 3,
      "boardName" : "IT 정보",
      "postHeaderId" : 3,
      "boardType" : "CLASSIC",
      "ownerName" : "(주)다우기술",
      "title" : "05월 21일  다우 뉴스피드",
      "content" : "...&#45824;&#54805;&#49324; '&#44596;&#51109;'_&#45684;&#49828;&#53664;&#47560;&#53664; &quot;&#44552;&#47532;&#51064;&#54616; 10&#50900; &#50696;&#49345;&hellip;&#49457;&#51109;&#47456; 2.48%&#47196; &#49345;&#54693;&quot;_&#49436;&#50872;&#44221;&#51228; AI <strong class='txt_key'>&#44160;&#49353;</strong> &#45824;&#51204; &#48376;&#44201;&#54868;",
      "hiddenPost" : false
    }
  ];

  return HttpResponse.json({
    "page" : {
      "page" : page,
      "offset" : offset,
      "total" : 85,
      "totalPage" : 17,
      "lastPage" : false
    },
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "data" : [...respData.slice(0, offset)]
  });
}

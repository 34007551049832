'use client';
import { PropsWithChildren } from 'react';
import { useContext } from './primitives/provider';
import { SimpleCodeFormClient } from './simple-code-form-client';

/**
 * @component AppScreenLock
 * 간편비밀번호 전체 로직이 포함되어 있는 ScreenLock.
 */
export function AppScreenLock({ children }: PropsWithChildren) {
  const { lockState, setLockState, appInfo } = useContext();

  if (!lockState) {
    return <>{children}</>;
  } else {
    return (
      <div className="flex size-full justify-center main">
        <SimpleCodeFormClient
          title={appInfo?.appName}
          appCode={appInfo?.appCode}
          type="web"
          onSuccess={() => setLockState && setLockState(false)}
        />
      </div>
    );
  }

  /**
   * @description 그룹웨어 로드 안 될 경우를 대비한, Page 이동 로직 (window.location.href를 사용해 Reload 발생하는 문제가 있음.)
   */
  // useEffect(() => {
  //   if (appInfo.appUrl !== pathname) {
  //     setLockState(false);
  //   }
  // }, [pathname, appInfo.appUrl, setLockState]);

  // if (lockState) { && appInfo.appUrl) {
  //   // if (appInfo.appName && appInfo.appCode && appInfo.appUrl) {
  //   //   const queryString: Record<string, string> = {
  //   //     appName: appInfo.appName,
  //   //     appCode: appInfo.appCode,
  //   //     url: appInfo.appUrl,
  //   //   };
  //   //   window.location.href = `/simple-code?${(new URLSearchParams(queryString)).toString()}`;
  //   // }
  // } else {
  //   return <>{children}</>;
  // }
}

export default AppScreenLock;

import { HttpResponse } from 'msw';

export function getCompanyBoardMenuesolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 1,
      "name" : "사내공지",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : false,
        "removable" : false,
        "writable" : false,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : true
      }
    }, {
      "id" : 2,
      "name" : "경조사",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : false,
        "removable" : false,
        "writable" : false,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 7,
      "name" : "열린마당",
      "type" : "STREAM",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      }
    }, {
      "id" : 1771,
      "name" : "CEO와의 대화",
      "type" : "STREAM",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      }
    }, {
      "id" : 1550,
      "name" : "마케팅 툴킷",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : true
      }
    }, {
      "id" : 3,
      "name" : "IT 정보",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4,
      "name" : "사내활동",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : true
      }
    }, {
      "id" : 840,
      "name" : "팀 커뮤니케이션",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : true
      }
    }, {
      "id" : 6,
      "name" : "사내제안",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4470,
      "name" : "다울림게시판",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 36,
      "name" : "주간식단표",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4670,
      "name" : "[개발센터] 오픈소스 라이브러리",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : false,
        "removable" : false,
        "writable" : false,
        "managable" : false
      },
      "header" : {
        "headerFlag" : true,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 3000,
      "name" : "[임시] 장애대응 게시판",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 620,
      "name" : "유류비관리 개선 요청",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 740,
      "name" : "DLP 문의 게시판",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    } ]
  });
}

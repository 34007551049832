import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveHr: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));
  const respData = [
    {
      "id": "4471c9bb-fbb7-4d9d-acab-ec08e0fb3ebc",
      "status": "WRITING",
      "name": "재직증명서",
      "requestDate": "2024-11-30",
      "purpose": "대출",
      "submitPlace": "신한은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20241130-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "81f57d9f-5a4c-4396-baf4-24ca9e9991f3",
      "status": "IN_APPROVAL",
      "name": "경력증명서",
      "requestDate": "2024-09-30",
      "purpose": "대출",
      "submitPlace": "국민은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240930-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "5bff2f26-af7a-4695-a841-031538f27348",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "e896d53f-63eb-46ba-99ce-b0e89d31d2f0",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "1ee79ab7-835b-42de-9e53-ca5f15ede1ba",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "1b06a165-d2bf-4bba-9acb-dbf705bbb4d2",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "b7f35dc4-b6df-413e-aab7-35f9be56f070",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "52744f39-ba5e-4e4f-b870-0378990d2a2d",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "6678a7a0-f34f-4085-b726-bf04f2497db2",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
    {
      "id": "70e844a5-d0b2-421c-a709-22a146e0e209",
      "status": "APPROVED",
      "name": "재직증명서",
      "requestDate": "2024-07-30",
      "purpose": "대출",
      "submitPlace": "기업은행",
      "validDate": "2024-12-31",
      "approvalNumber": "20240730-00001",
      "remarks": "대출 심사용"
    },
  ];

  return HttpResponse.json({
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "data" : { 
      "list": [...respData.slice(0, offset)],
      "page" : {
        "page" : page,
        "offset" : offset,
        "total" : 10,
        "totalPage" : 2,
      }
    }
  });
}

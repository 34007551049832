import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveExpenses: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));
  const respData = [
    {
      "id": "4471c9bb-fbb7-4d9d-acab-ec08e0fb3ebc",
      "status": "WRITING",
      "type": "FUEL_COST",
      "name": "353루 5015",
      "dateTime": "2024-11-30T09:00:00",
      "amount": 10,
      "distance": 10,
      "fuel": "디젤",
      "purpose": "식사",
      "starting": "모란역",
      "destination": "다우기술"
    },
    {
      "id": "81f57d9f-5a4c-4396-baf4-24ca9e9991f3",
      "status": "WAITING",
      "type": "CORPORATE_CARD",
      "name": "신한S20 [이진규] XXXX-XX**-****-XXXX",
      "usage": null,
      "dateTime": "2024-11-29T09:00:00",
      "amount": 10,
      "purpose": "식대",
      "detailPurpose": "아메리카노",
      "project": "차세대 다우오피스"
    },
    {
      "id": "5bff2f26-af7a-4695-a841-031538f27348",
      "status": "APPROVED",
      "type": "PERSONAL",
      "receiptType": "개인경비",
      "dateTime": "2024-11-28T09:00:00",
      "usage": "카페오",
      "amount": 10,
      "claimAmount": 10,
      "purpose": "팀 회의",
      "detailPurpose": "아메리카노",
      "project": "차세대 다우오피스"
    },
    {
      "id": "e896d53f-63eb-46ba-99ce-b0e89d31d2f0",
      "status": "IN_APPROVAL",
      "type": "CORPORATE_CARD",
      "name": "신한S20 [이진규] XXXX-XX**-****-XXXX",
      "usage": null,
      "dateTime": "2024-11-29T09:00:00",
      "amount": 10,
      "purpose": "식대",
      "detailPurpose": "아메리카노",
      "project": "차세대 다우오피스"
    }
  ];

  return HttpResponse.json({
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "data" : { 
      "list": [...respData.slice(0, offset)],
      "page" : {
        "page" : page,
        "offset" : offset,
        "total" : 3,
        "totalPage" : 1,
      }
    }
  });
}

import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveCommunity: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));

  const respData = [
    {
      "id" : 93450,
      "createdAt" : "2024-12-18T15:28:48.106+09:00",
      "updatedAt" : "2024-12-18T16:51:15.174+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/12/18(수)\n\n1. 공통 이슈사항\n  - WBS 업데이트 \n    : ",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/12/18(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid",
      "comments" : "1. PC메신저 Html 다운 기능 제거 \n  - 사용 통계 확인 완료\n  - html 제거, txt만 유지 (12/8 PM 결정)\n\n2. PC메신저 Zoom 연동 서비스 기존 기능 유지  \n  - 범위 : 통합설정 App관리 / PC메신저 \n\n3. 회사 앱별 삭제 정책 (김일남 책임 확인중)\n  - DCD 정책 유지 여부, 삭제하면 안되는 앱의 정책 확인 필요",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 93201,
      "createdAt" : "2024-12-04T15:08:42.331+09:00",
      "updatedAt" : "2024-12-04T15:08:42.332+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/12/04(수)\n\n1. 공통 이슈사항\n  - WBS 업데이트 \n    : ",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/12/04(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 93095,
      "createdAt" : "2024-11-27T15:19:56.886+09:00",
      "updatedAt" : "2024-11-27T15:19:56.887+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/11/27(수)\n\n1. 공통 이슈사항\n  - WBS 업데이트 \n    : ",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/11/27(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid",
      "hiddenPost" : false,
      "communityId" : 1324
    }, {
      "id" : 93093,
      "createdAt" : "2024-11-27T14:24:22.519+09:00",
      "updatedAt" : "2024-12-04T15:40:43.525+09:00",
      "userId" : 4244,
      "writer" : {
        "id" : 4244,
        "name" : "김현경",
        "positionName" : "선임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/1021397-22941"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "11월 27일(수) 테마 적용 범위 관련 <strong class='txt_key'>회의</strong>\n\n참석자 - 이재희책임, 이경희책임, ",
      "content" : "11&#50900; 27&#51068;(&#49688;) &#53580;&#47560; &#51201;&#50857; &#48276;&#50948; &#44288;&#47144; <strong class='txt_key'>&#54924;&#51032;</strong> &#52280;&#49437;&#51088; - &#51060;&#51116;&#55148;&#52293;&#51076;, &#51060;&#44221;&#55148;&#52293;&#51076;, &#44053;&#48393;&#49688;&#52293;&#51076;",
      "comments" : "5. 로고는 따로 받지 않고, 텍스트로 노출하고 드롭다운으로 멀티컴퍼니 목록 노출되는 형태로 하겠습니다.\n권한이 없을 경우 회사명 노출되지 않습니다 . ",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92956,
      "createdAt" : "2024-11-20T15:39:20.872+09:00",
      "updatedAt" : "2024-11-20T15:40:41.919+09:00",
      "userId" : 3937,
      "writer" : {
        "id" : 3937,
        "name" : "김성래",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/747361-34938"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[조직도 근태상태 제공방안 협의 <strong class='txt_key'>회의</strong>] - 2024/11/20(수)\n\n[참석자]\n김성래 책임 ",
      "content" : "[&#51312;&#51649;&#46020; &#44540;&#53468;&#49345;&#53468; &#51228;&#44277;&#48169;&#50504; &#54801;&#51032; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/11/20(&#49688;) [&#52280;&#49437;&#51088;] &#44608;&#49457;&#47000; &#52293;&#51076; &#54861;&#49849;&#54364; &#52293;&#51076; &#44608;&#46041;&#54617;",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92954,
      "createdAt" : "2024-11-20T15:02:05.729+09:00",
      "updatedAt" : "2024-11-20T15:02:05.730+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/11/20(수)\n\n1. 공통 이슈사항\n  - WBS 업데이트 \n    : ",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/11/20(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92837,
      "createdAt" : "2024-11-13T15:15:08.388+09:00",
      "updatedAt" : "2024-11-13T15:15:08.392+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/11/13(수)\n\n1. 공통 이슈사항\n  - MBO 대비 산출물 정",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/11/13(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - MBO &#45824;&#48708; &#49328;&#52636;&#47932; &#51221;&#47532; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92582,
      "createdAt" : "2024-10-30T15:12:22.702+09:00",
      "updatedAt" : "2024-10-30T15:12:22.703+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/10/30(수)\n\n1. 공통 이슈사항  \n  - WBS 업데이트 \n    ",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/10/30(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92479,
      "createdAt" : "2024-10-23T15:00:39.960+09:00",
      "updatedAt" : "2024-10-23T15:00:39.960+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/10/23(수)\n\n1. 공통 이슈사항  \n  - WBS 업데이트 \n    ",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/10/23(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92454,
      "createdAt" : "2024-10-22T17:26:20.021+09:00",
      "updatedAt" : "2024-10-22T18:56:05.509+09:00",
      "userId" : 5252,
      "writer" : {
        "id" : 5252,
        "name" : "박민주",
        "positionName" : "사원",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/2398969-33196"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[통합 스토리지 <strong class='txt_key'>회의</strong>] - 2024/10/22(화)\n\n- <strong class='txt_key'>회의</strong> 목적\n    - 스토리지 파일 삭제 플",
      "content" : "[&#53685;&#54633; &#49828;&#53664;&#47532;&#51648; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/10/22(&#54868;) - <strong class='txt_key'>&#54924;&#51032;</strong> &#47785;&#51201; - &#49828;&#53664;&#47532;&#51648; &#54028;&#51068; &#49325;&#51228; &#54540;&#47196;&#50864; &#44208;&#51221; - &#54028;&#51068; &#49325;&#51228;&#49884;",
      "comments" : "플로우 차트 추가 필요",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92421,
      "createdAt" : "2024-10-21T13:40:26.440+09:00",
      "updatedAt" : "2024-11-07T11:07:40.658+09:00",
      "userId" : 5690,
      "writer" : {
        "id" : 5690,
        "name" : "이지은",
        "positionName" : "사원",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/3248001-69951"
      },
      "boardId" : 4370,
      "boardName" : "기획서",
      "boardType" : "CLASSIC",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[기획서] 경영업무포털 외부기관 연동센터 상세기획 - 완료",
      "content" : ". &nbsp; (11/7 update) - &nbsp;&#53685;&#51109;/&#52852;&#46300; &#47700;&#45684; &#50948;&#52824;&#45716; SNB&#50640;&#49436; &#51228;&#44277;&#54616;&#51648; &#50506;&#51020;. &#50545;&#51004;&#47196; &#51228;&#44277;&#54616;&#45716; &#44163;&#51004;&#47196; &#54801;&#51032; -&nbsp; <strong class='txt_key'>&#54924;&#51032;&#47197;</strong>",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92327,
      "createdAt" : "2024-10-16T15:43:16.577+09:00",
      "updatedAt" : "2024-10-17T12:12:36.807+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/10/16(수)\n\n1. 공통 이슈사항  \n  - WBS 업데이트 \n    ",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/10/16(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#50629;&#45936;&#51060;&#53944; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid",
      "comments" : "1.  포털 [개발] 그룹웨어 특화 조직도 대응 개발 \n     ▶︎ 개발 담당자에게 특화 조직도가 기획대로 개발 안되는 걸로 전달 받음. \n        개발팀에 기획서에 개발 유무 표기 해달라 요청드림.\n\n2. 모바일[개발] 가젯 진행 이슈\n     ▶︎ 개발에서 제시해주신 다운그레이드는 기획, 디자인 모두 추가 공수 필요\n     ▶︎ 기존 가젯 기능 그대로 붙일지 다운그레이드를 할지는 추가 협의가 필요함.",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92192,
      "createdAt" : "2024-10-08T14:24:49.799+09:00",
      "updatedAt" : "2024-10-08T14:24:49.800+09:00",
      "userId" : 3937,
      "writer" : {
        "id" : 3937,
        "name" : "김성래",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/747361-34938"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[신근태 DB <strong class='txt_key'>회의</strong>]\n\n참석자: 남현진, 김성래, 김한조\n일시: 2024-10-08 13:00 ~ 14:00\n\n",
      "content" : "[&#49888;&#44540;&#53468; DB <strong class='txt_key'>&#54924;&#51032;</strong>] &#52280;&#49437;&#51088;: &#45224;&#54788;&#51652;, &#44608;&#49457;&#47000;, &#44608;&#54620;&#51312; &#51068;&#49884;: 2024-10-08 13:00 ~ 14:00 <strong class='txt_key'>&#54924;&#51032;</strong> &#51088;&#47308; https://wiki.daou.co.kr/pages/viewpage.action?pageId=106971399",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92118,
      "createdAt" : "2024-10-02T17:06:36.803+09:00",
      "updatedAt" : "2024-10-02T17:06:36.804+09:00",
      "userId" : 3937,
      "writer" : {
        "id" : 3937,
        "name" : "김성래",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/747361-34938"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[신근태 <strong class='txt_key'>회의</strong>]\n\n \n\n참석자: \n\n김성래 책임 곽면순 책임 김세현 책임 이재희 책",
      "content" : "[&#49888;&#44540;&#53468; <strong class='txt_key'>&#54924;&#51032;</strong>] &#52280;&#49437;&#51088;: &#44608;&#49457;&#47000; &#52293;&#51076; &#44285;&#47732;&#49692; &#52293;&#51076; &#44608;&#49464;&#54788; &#52293;&#51076; &#51060;&#51116;&#55148; &#52293;&#51076; &#49436;&#52285;&#54840; &#52293;&#51076; -&gt; TCK",
      "hiddenPost" : false,
      "communityId" : 1324
    }, 
    {
      "id" : 92111,
      "createdAt" : "2024-10-02T15:07:14.822+09:00",
      "updatedAt" : "2024-10-02T15:07:14.823+09:00",
      "userId" : 260,
      "writer" : {
        "id" : 260,
        "name" : "김은미",
        "positionName" : "책임",
        "otherCompanyUser" : false,
        "thumbSmall" : "/thumb/user/small/508483-119191"
      },
      "boardId" : 4373,
      "boardName" : "회의록",
      "boardType" : "STREAM",
      "ownerName" : "다우오피스 차세대 플랫폼 프로젝트",
      "title" : "[파트 리더 정기 <strong class='txt_key'>회의</strong>] - 2024/10/02(수)\n\n1. 공통 이슈사항  \n  - WBS 정리 \n    : http",
      "content" : "[&#54028;&#53944; &#47532;&#45908; &#51221;&#44592; <strong class='txt_key'>&#54924;&#51032;</strong>] - 2024/10/02(&#49688;) 1. &#44277;&#53685; &#51060;&#49800;&#49324;&#54637; - WBS &#51221;&#47532; : https://docs.google.com/spreadsheets/d/1sBSFdg0gBQyz3f92eZpz2n2RqFU6CRTK8PJaGVs0jAc/edit?gid=1692473640#gid=1692473640 2",
      "hiddenPost" : false,
      "communityId" : 1324
    } 
  ];

  return HttpResponse.json({
    "page" : {
      "page" : page,
      "offset" : offset,
      "total" : 92,
      "totalPage" : 8,
      "lastPage" : false
    },
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [...respData.slice(0, offset)]
  });
}

import { http } from 'msw';

// import { getEmpUserMenuResolver } from './resolvers/get-emp-user-menu';
// import { getSharedAssetListResolver } from './resolvers/global-config/get-shared-asset-list';
import * as integrated from './resolvers/search/integrated';
import { getDepartmentListResolver } from './resolvers/get-department-list';
import { getBoardMenuOwnerResolver } from './resolvers/get-board-menu-owners';
import { getDeptBoardMenuesolver } from './resolvers/get-dept-board-menu';
import { getCompanyBoardMenuesolver } from './resolvers/get-company-board-menu';
import { getMyCommunityListResolver } from './resolvers/get-my-community-list';
import { getCommuntyBoardMenuesolver } from './resolvers/get-community-board-menu';
import { getDocsFolderMenuResolver } from './resolvers/get-docs-folder-menu';
import { getReportDeptListResolver } from './resolvers/get-report-department-list';
import { getMailFolderInfoResolver } from './resolvers/get-mail-folder-info';
import { getWorksAppletListResolver } from './resolvers/get-works-applets';

export const handlers = [
  // http.get('/api/portal/common/menu/user/employee', getEmpUserMenuResolver),
  // http.get(
  //   '/gw/ad/api/system/companygroup/2/asset/shares',
  //   getSharedAssetListResolver,
  // ),

  // 통합검색
  http.post('/api/mail/message/list/search', integrated.mail),
  http.get('/api/mail/webfolder/folder/list/search', integrated.mail),
  http.get('/gw/api/contact/search', integrated.contact),
  http.get('/gw/api/search/approval', integrated.approval),
  http.get('/gw/api/search/works', integrated.works),
  http.get('/gw/api/search/board', integrated.board),
  http.get('/gw/api/search/calendar', integrated.calendar),
  http.get('/gw/api/search/community', integrated.community),
  http.get('/gw/api/search/report', integrated.report),
  http.get('/gw/api/search/todo', integrated.todo),
  http.get('/gw/api/search/docs', integrated.docs),
  http.get('/eacc/api/ess/hr/search', integrated.hr),
  http.get('/eacc/api/ess/hr/search/detail', integrated.hr),
  http.get('/eacc/api/ess/expenses/search', integrated.expenses),
  http.get('/eacc/api/ess/expenses/search/detail', integrated.expenses),
  http.get(
    '/eacc/api/ess/expenses/search/detail/personal-expenses',
    integrated.expenses,
  ),
  http.get(
    '/eacc/api/ess/expenses/search/detail/fuel-cost',
    integrated.expenses,
  ),
  http.get(
    '/eacc/api/ess/expenses/search/detail/corporate-card',
    integrated.expenses,
  ),
  http.get('/eacc/api/ess/legal-education/search', integrated.legalEducation),
  http.get(
    '/eacc/api/ess/legal-education/search/detail',
    integrated.legalEducation,
  ),
  http.get('/eacc/api/ess/vehicle-log/search', integrated.vehicleLog),
  http.get('/eacc/api/ess/vehicle-log/search/detail', integrated.vehicleLog),
  http.get('/eacc/api/ess/econtract/search', integrated.econtract),
  http.get('/eacc/api/ess/econtract/search/detail', integrated.econtract),

  // 부서 목록 조회
  http.get('/gw/api/department/list/joined', getDepartmentListResolver),
  // 조회 가능한 게시판 메뉴 조회
  http.get('/gw/api/board/menu/target/owners', getBoardMenuOwnerResolver),
  // 조회 가능한 부서별 게시판 메뉴 조회
  http.get(
    '/gw/api/board/menu/department/:deptId/all',
    getDeptBoardMenuesolver,
  ),
  // 조회 가능한 전사게시판 메뉴 조회
  http.get('/gw/api/board/menu/target/owners', getCompanyBoardMenuesolver),

  // 내 커뮤니티 목록
  http.get('/gw/api/board/menu/communities', getMyCommunityListResolver),
  // 커뮤니티 게시판 목록 조회
  http.get(
    '/gw/api/board/menu/community/:commId/all',
    getCommuntyBoardMenuesolver,
  ),

  // 전자문서함 위치 목록 조회
  http.get('/gw/api/docs/folder/side', getDocsFolderMenuResolver),

  // 보고 > 부서목록
  http.get('/gw/api/report/department', getReportDeptListResolver),

  // 메일함 정보 조회
  http.get('/gw/api/mail/folder/info', getMailFolderInfoResolver),

  // Works > 애플릿 목록 조회
  http.get('/gw/api/works/applets', getWorksAppletListResolver),
];

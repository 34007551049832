import * as React from 'react';
import { useDownloadReasonDialogState } from '../models/use-download-reason-dialog-state';
import { Context } from './context';
import { DownloadReasonDialog } from './download-reason-dialog';

export function Provider({ children }: React.PropsWithChildren) {
  const { dialogState, handleResolve, handleReject, openDownloadReasonDialog } =
    useDownloadReasonDialogState();

  const ctxVal = {
    openDownloadReasonDialog: () =>
      openDownloadReasonDialog(dialogState.defaultReason),
  };

  return (
    <Context value={ctxVal}>
      {children}
      <DownloadReasonDialog
        downloadReason={dialogState.defaultReason}
        open={dialogState.open}
        onOpenChange={() => close()}
        onConfirm={handleResolve}
        onCancel={handleReject}
      />
    </Context>
  );
}

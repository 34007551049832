import { HttpResponse } from 'msw';

export function getDeptBoardMenuesolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 187,
      "name" : "SW연구소 열린마당",
      "type" : "STREAM",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      }
    }, {
      "id" : 3472,
      "name" : "스터디 자료 공유 게시판 ",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    }, {
      "id" : 4229,
      "name" : "품질관리팀 분기별 결함사례 취합",
      "type" : "CLASSIC",
      "actions" : {
        "updatable" : true,
        "removable" : true,
        "writable" : true,
        "managable" : false
      },
      "header" : {
        "headerFlag" : false,
        "headerRequiredFlag" : false
      }
    } ]
  });
}

'use client';

import type { PropsWithChildren } from 'react';
import { ScreenLockTypes } from '../types';
import { AppScreenLock } from './app-screen-lock';
import { ExtScreenLock } from './ext-screen-lock';

export interface Props {
  lockType?: ScreenLockTypes;
  title?: string;
}

export function ScreenLock({
  children,
  lockType = 'app',
  title,
}: PropsWithChildren<Props>) {
  return (
    <>
      {lockType === 'app' ? (
        <AppScreenLock>{children}</AppScreenLock>
      ) : (
        <ExtScreenLock title={title}>{children}</ExtScreenLock>
      )}
    </>
  );
}

ScreenLock.displayName = 'ScreenLock';

export default ScreenLock;

import { HttpResponse } from 'msw';

export function getMailFolderInfoResolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : "defaultFolder0",
      "name" : "받은메일함",
      "fullName" : "Inbox",
      "encName" : "Inbox",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 51,
      "totalCnt" : 301,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 328018342,
      "domainFolder" : false,
      "smartFolder" : false,
      "usageUnit" : "312.8MB"
    }, {
      "id" : "defaultFolder1",
      "name" : "보낸메일함",
      "fullName" : "Sent",
      "encName" : "Sent",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 38,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 12710691,
      "domainFolder" : false,
      "smartFolder" : false,
      "usageUnit" : "12.1MB"
    }, {
      "id" : "defaultFolder2",
      "name" : "임시보관함",
      "fullName" : "Drafts",
      "encName" : "Drafts",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 0,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 0,
      "domainFolder" : false,
      "smartFolder" : false,
      "usageUnit" : "0Byte"
    }, {
      "id" : "defaultFolder3",
      "name" : "예약메일함",
      "fullName" : "Reserved",
      "encName" : "Reserved",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 0,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 0,
      "domainFolder" : false,
      "smartFolder" : false,
      "usageUnit" : "0Byte"
    }, {
      "id" : "defaultFolder4",
      "name" : "스팸메일함",
      "fullName" : "Spam",
      "encName" : "Spam",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 0,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 0,
      "domainFolder" : false,
      "smartFolder" : false,
      "usageUnit" : "0Byte"
    }, {
      "id" : "defaultFolder5",
      "name" : "휴지통",
      "fullName" : "Trash",
      "encName" : "Trash",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 0,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 0,
      "domainFolder" : false,
      "smartFolder" : false,
      "usageUnit" : "0Byte"
    }, {
      "id" : "userFolder0",
      "name" : "PR 알림",
      "fullName" : "Inbox.PR 알림",
      "encName" : "Inbox.PR &xUy5vA-",
      "depth" : 1,
      "parentFullName" : "Inbox",
      "newCnt" : 57,
      "unseenCnt" : 57,
      "totalCnt" : 75,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 2521844,
      "domainFolder" : false,
      "smartFolder" : false,
      "usageUnit" : "2.4MB"
    }, {
      "id" : "userFolder1",
      "name" : "광고 메일함",
      "fullName" : "adv_box",
      "encName" : "adv_box",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 0,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 0,
      "domainFolder" : false,
      "smartFolder" : true,
      "usageUnit" : "0Byte"
    }, {
      "id" : "userFolder2",
      "name" : "청구서 메일함",
      "fullName" : "bill_box",
      "encName" : "bill_box",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 0,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 0,
      "domainFolder" : false,
      "smartFolder" : true,
      "usageUnit" : "0Byte"
    }, {
      "id" : "userFolder3",
      "name" : "소셜 메일함",
      "fullName" : "sns_box",
      "encName" : "sns_box",
      "depth" : 0,
      "newCnt" : 0,
      "unseenCnt" : 0,
      "totalCnt" : 0,
      "share" : false,
      "sharedUid" : 0,
      "aging" : 0,
      "groupAging" : 0,
      "usage" : 0,
      "domainFolder" : false,
      "smartFolder" : true,
      "usageUnit" : "0Byte"
    } ]
  });
}

import { fetch } from '../../../shared/lib/fetch/client';

export const QUERY_KEY = 'check-screen-lock-target-query';

const API_SCREEN_LOCK_SIMPLE_CODE_URL =
  '/api/portal/common/simple-code/screen-lock';

export interface ResponseBody {
  data: {
    isScreenLockTarget: boolean;
    remainMillisToCheckScreenLock: number;
  };
}

export async function checkScreenLockTarget(appCode?: string) {
  const defaultResponse = {
    data: {
      isScreenLockTarget: false,
      remainMillisToCheckScreenLock: 0,
    },
  } as ResponseBody;

  if (!appCode) {
    return defaultResponse;
  }

  const res = await fetch(`${API_SCREEN_LOCK_SIMPLE_CODE_URL}/${appCode}`);

  if (!res.ok) {
    return defaultResponse;
  }

  return (await res.json()) as ResponseBody;
}

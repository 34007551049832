import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveContact: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));

  return HttpResponse.json({
    "page" : {
      "page" : page,
      "offset" : offset,
      "total" : 85,
      "totalPage" : 17,
      "lastPage" : false
    },
    "hasNext" : false,
    "code" : "200",
    "message" : "OK",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 1,
      "createdAt" : "2024-12-11T16:24:37.646+09:00",
      "updatedAt" : "2024-12-11T16:24:37.646+09:00",
      "deletedFlag" : false,
      "email" : "aa@test.co",
      "name" : "박다우",
      "nameInitialConsonant" : "ㄱ",
      "nameHurigana" : "",
      "mobileNo" : "010-1234-1234",
      "companyName" : "",
      "groups" : [ {
        "id" : 167341,
        "createdAt" : "2024-12-11T16:24:17.247+09:00",
        "publicFlag" : true,
        "name" : "내 주소록",
        "contactCount" : 2,
        "seq" : 0,
        "children" : [ ]
      } ],
      "photoPath" : "", 
      "bookOwner" : {
        "id" : 2491,
        "ownerType" : "USER",
        "ownerId" : 2931
      }
    }, {
      "id" : 2,
      "deletedFlag" : false,
      "email" : "test@test.te",
      "name" : "김 다우",
      "nameInitialConsonant" : "ㄱ",
      "nameHurigana" : "",
      "firstName" : "다우",
      "middleName" : "",
      "lastName" : "김",
      "nickName" : "",
      "mobileNo" : "010-1234-1234",
      "companyName" : "테스트 회사 ",
      "departmentName" : "",
      "positionName" : "",
      "description" : "제목 메모",
      "home" : {
        "id" : 1948021,
        "tel" : "",
        "fax" : "",
        "postalCode" : "우편번호",
        "country" : "국가",
        "state" : "시/도",
        "city" : "구/군",
        "basicAddress" : "국가 우편번호 시/도 구/군 기타주소 ",
        "extAddress" : "기타주소",
        "homepage" : ""
      },
      "office" : {
        "id" : 1948022,
        "tel" : "02-2222-2222",
        "fax" : "",
        "postalCode" : "",
        "country" : "",
        "state" : "",
        "city" : "",
        "basicAddress" : "",
        "extAddress" : "",
        "homepage" : ""
      },
      "photoPath" : "company01/20241210/f9f6f633-e40f-4c27-a6e8-ef3d9ae8ae69", 
      "bookOwner" : {
        "id" : 112743,
        "ownerType" : "USER",
        "ownerId" : 80955
      }
    }]
  });
}

import { http } from 'msw';

import { attendApiResolver } from './resolvers/attend-api-resolver';

export const attendHandlers = [
  // 아래 형식처럼 정의
  http.get('/api/attend/**', attendApiResolver),
  http.post('/api/attend/**', attendApiResolver),
  http.put('/api/attend/**', attendApiResolver),
  http.delete('/api/attend/**', attendApiResolver),
  http.get('/api/leave/**', attendApiResolver),
  http.post('/api/leave/**', attendApiResolver),
  http.put('/api/leave/**', attendApiResolver),
  http.delete('/api/leave/**', attendApiResolver),
];

import * as React from 'react';
import { createStore, Provider as JotaiStoreProvider } from 'jotai';

let browserJotaiStore: ReturnType<typeof createStore> | undefined;

function getGlobalStore() {
  if (typeof window === 'undefined') {
    // 서버사이드 렌더링 시에는 매 요청마다 새로운 쿼리 클라이언트를 생성
    return createStore();
  } else {
    if (!browserJotaiStore) browserJotaiStore = createStore();
    return browserJotaiStore;
  }
}

export default function JotaiGlobalStoreProvider({
  children,
}: React.PropsWithChildren) {
  const globalStore = getGlobalStore();

  return (
    <JotaiStoreProvider store={globalStore}>{children}</JotaiStoreProvider>
  );
}

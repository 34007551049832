import { HttpResponse } from 'msw';

export function getMyCommunityListResolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 1324,
      "name" : "다우오피스 차세대 플랫폼 프로젝트"
    }, {
      "id" : 670,
      "name" : "\uD83D\uDC2CS/W개발본부 On-Day"
    } ]
  });
}

import { HttpResponse } from 'msw';

export function resolveMail() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : {
      "extFolder" : "off",
      "folderType" : "all",
      "folderName" : "",
      "folderFullName" : "all",
      "folderEncName" : "",
      "messageCount" : 0,
      "unreadMessageCount" : 0,
      "currentPage" : 1,
      "pageBase" : 5,
      "total" : 7,
      "dateFormatUtc" : {
        "0" : "2024-12-22T22:46:02.868Z",
        "1" : "2024-12-21T22:46:02.868Z",
        "2" : "2024-12-20T22:46:02.868Z",
        "3" : "2024-12-19T22:46:02.868Z",
        "4" : "2024-12-18T22:46:02.868Z",
        "5" : "2024-12-17T22:46:02.868Z",
        "6" : "2024-12-16T22:46:02.868Z"
      },
      "pageInfo" : {
        "pages" : [ 1, 2 ],
        "firstWindow" : true,
        "firstPage" : true,
        "lastWindow" : false,
        "lastPage" : false,
        "preWindow" : -1,
        "prePage" : 1,
        "nextWindow" : 2,
        "nextPage" : 2,
        "page" : 1,
        "pageSize" : 5,
        "total" : 7
      },
      "messageList" : [ {
        "id" : 5155,
        "folderName" : "Inbox",
        "folderEncName" : "Inbox",
        "folderFullName" : "Inbox",
        "folderDepthName" : "Inbox",
        "tagList" : [ ],
        "subject" : "[포털서비스개발팀 정효인] 하반기 멘토/멘티 문서 제출",
        "from" : "\"정효인\"<hyoinjeong@daou.co.kr>",
        "fromEscape" : "\"정효인\"<hyoinjeong@daou.co.kr>",
        "fromToSimple" : "정효인",
        "to" : "kbsbroad@daou.co.kr",
        "toEscape" : "kbsbroad@daou.co.kr",
        "sendToSimple" : "kbsbroad@daou.co.kr",
        "size" : "41.3KB",
        "byteSize" : 42253,
        "flag" : "S",
        "preview" : "안녕하세요, 봉수책임님. 하반기 멘토/멘티 활동에 필요한 문서를 아래와 같이 정리해서 보내드립니다.   1. 수행했던 개발 목록 (지라 링크 포함) 내 강의실 가젯 신규 개발 ﻿ https&#58;//i",
        "dateUtc" : "2024-11-26T01:27:33.000Z",
        "term" : 9,
        "sentDateUtc" : "2024-11-26T01:27:33.000Z",
        "seen" : true,
        "priority" : "NORMAL",
        "spamRate" : 0.0,
        "existMeInCc" : false
      }, {
        "id" : 4622,
        "folderName" : "Inbox",
        "folderEncName" : "Inbox",
        "folderFullName" : "Inbox",
        "folderDepthName" : "Inbox",
        "tagList" : [ ],
        "subject" : "DO NEXT 프로젝트에서 Slack Pro 무료 평가판을 시작했습니다.",
        "from" : "\"Slack\"<no-reply@slack.com>",
        "fromEscape" : "\"Slack\"<no-reply@slack.com>",
        "fromToSimple" : "Slack",
        "to" : "kbsbroad@daou.co.kr",
        "toEscape" : "kbsbroad@daou.co.kr",
        "sendToSimple" : "kbsbroad@daou.co.kr",
        "size" : "38.5KB",
        "byteSize" : 39398,
        "flag" : "S",
        "preview" : "DO NEXT 프로젝트에서 Slack Pro 무료 평가판을 시작했습니다. 팀을 위해 잠금 해제된 기능 보기  ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌  ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ",
        "dateUtc" : "2024-10-16T06:18:11.000Z",
        "term" : 10,
        "sentDateUtc" : "2024-10-16T06:18:07.000Z",
        "seen" : true,
        "priority" : "NORMAL",
        "spamRate" : 0.0,
        "existMeInCc" : false
      }, {
        "id" : 25,
        "folderName" : "Sent",
        "folderEncName" : "Sent",
        "folderFullName" : "Sent",
        "folderDepthName" : "Sent",
        "tagList" : [ ],
        "subject" : "신입사원에게 지급된 맥북 프로의 외부모니터 지원에 대한 건",
        "from" : "\"강봉수\"<kbsbroad@daou.co.kr>",
        "fromEscape" : "\"강봉수\"<kbsbroad@daou.co.kr>",
        "fromToSimple" : "강봉수",
        "to" : "\"정의만/책임/사업지원팀\"<ymjung@daou.co.kr>",
        "toEscape" : "\"정의만/책임/사업지원팀\"<ymjung@daou.co.kr>",
        "sendToSimple" : "정의만/책임/사업지원팀",
        "size" : "563.8KB",
        "byteSize" : 577315,
        "flag" : "S",
        "preview" : "안녕하세요, 비즈플랫폼팀 강봉수 책임입니다. 금주 지급된 신입사원의 개발장비 중 맥북 프로가 두대의 외부 모니터를 지원하지 않는 것으로 확인되어 문의드립니다.   현",
        "dateUtc" : "2024-04-11T08:14:32.000Z",
        "term" : 10,
        "sentDateUtc" : "2024-04-11T08:14:32.000Z",
        "seen" : true,
        "priority" : "NORMAL",
        "spamRate" : 0.0,
        "existMeInCc" : false
      }, {
        "id" : 1798,
        "folderName" : "Inbox",
        "folderEncName" : "Inbox",
        "folderFullName" : "Inbox",
        "folderDepthName" : "Inbox",
        "tagList" : [ {
          "id" : 3,
          "name" : "관심도 낮은 메일",
          "color" : "ai_low"
        } ],
        "subject" : "[다우오피스] 03월 12일 다우오피스 공유형 서버 자동화 기본 점검 결과 공유",
        "from" : "rpa003@daou.co.kr",
        "fromEscape" : "rpa003@daou.co.kr",
        "fromToSimple" : "rpa003@daou.co.kr",
        "to" : "saasmanage@daou.co.kr",
        "toEscape" : "saasmanage@daou.co.kr",
        "sendToSimple" : "saasmanage@daou.co.kr",
        "size" : "155.0KB",
        "byteSize" : 158739,
        "flag" : "S",
        "preview" : "\t \t\t \t\t\t \t\t\t\t \t\t\t \t\t \t \t \t\t \t\t\t \t\t\t\t \t\t\t \t\t \t 안녕하세요 \t\t\t\t\t\t\t\t\t\t\t 사내 업무자동화를 담당하는 &#91; 알봇 &#93;입니다. \t\t\t\t\t\t\t 금일 03월 12일 에 진행한 다우오피스 공유형 서버의 기본 점검 결과를 공유드립",
        "dateUtc" : "2024-03-11T23:44:11.000Z",
        "term" : 10,
        "sentDateUtc" : "2024-03-11T23:44:01.000Z",
        "seen" : true,
        "priority" : "NORMAL",
        "spamRate" : 0.0,
        "existMeInCc" : false
      }, {
        "id" : 1759,
        "folderName" : "Inbox",
        "folderEncName" : "Inbox",
        "folderFullName" : "Inbox",
        "folderDepthName" : "Inbox",
        "tagList" : [ {
          "id" : 3,
          "name" : "관심도 낮은 메일",
          "color" : "ai_low"
        } ],
        "subject" : "[다우오피스] 03월 05일 다우오피스 공유형 서버 자동화 기본 점검 결과 공유입니다. 이 테스트 메일은 메일 제목이 길어졌을 때에 대한 테스트입니다.",
        "from" : "rpa003@daou.co.kr",
        "fromEscape" : "rpa003@daou.co.kr",
        "fromToSimple" : "rpa003@daou.co.kr",
        "to" : "saasmanage@daou.co.kr",
        "toEscape" : "saasmanage@daou.co.kr",
        "sendToSimple" : "saasmanage@daou.co.kr",
        "size" : "155.0KB",
        "byteSize" : 158728,
        "flag" : "S",
        "preview" : "\t \t\t \t\t\t \t\t\t\t \t\t\t \t\t \t \t \t\t \t\t\t \t\t\t\t \t\t\t \t\t \t 안녕하세요 \t\t\t\t\t\t\t\t\t\t\t 사내 업무자동화를 담당하는 &#91; 알봇 &#93;입니다. \t\t\t\t\t\t\t 금일 03월 05일 에 진행한 다우오피스 공유형 서버의 기본 점검 결과를 공유드립",
        "dateUtc" : "2024-03-04T23:44:52.000Z",
        "term" : 10,
        "sentDateUtc" : "2024-03-04T23:44:41.000Z",
        "seen" : true,
        "priority" : "NORMAL",
        "spamRate" : 0.0,
        "existMeInCc" : false
      } ],
      "sortBy" : "arrival",
      "sortDir" : "desc",
      "listType" : "mail",
      "tagId" : 0,
      "bookmarkSeq" : 0,
      "bookmarkType" : "",
      "viewMeInCc" : false,
      "init" : false,
      "search" : true,
      "bookmark" : false,
      "exceptFolder" : false
    }
  });
}

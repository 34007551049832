import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveLegalEducation: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));
  const respData = [
    {
      "id": "4471c9bb-fbb7-4d9d-acab-ec08e0fb3ebc",
      "status": "WAITING",
      "name": "안전보건교육",
      "grade": "미수",
      "startDate": "2024-11-30",
      "endDate": "2024-12-31",
      "count": 5,
      "progressRate": 0
    },
    {
      "id": "81f57d9f-5a4c-4396-baf4-24ca9e9991f3",
      "status": "IN_PROGRESS",
      "name": "안전 예방 교육",
      "grade": "미수료",
      "startDate": "2024-10-30",
      "endDate": "2024-12-31",
      "count": 4,
      "progressRate": 40
    },
    {
      "id": "5bff2f26-af7a-4695-a841-031538f27348",
      "status": "COMPLETED",
      "name": "소방 안전 교육",
      "grade": "수료",
      "startDate": "2024-10-15",
      "endDate": "2024-12-31",
      "count": 7,
      "progressRate": 100
    }
  ];

  return HttpResponse.json({
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "data" : { 
      "list": [...respData.slice(0, offset)],
      "page" : {
        "page" : page,
        "offset" : offset,
        "total" : 3,
        "totalPage" : 1,
      }
    }
  });
}

import { HttpResponse } from 'msw';

export function resolveCalendar() {
  return HttpResponse.json({
    "page" : {
      "page" : 0,
      "offset" : 5,
      "total" : 41,
      "totalPage" : 9,
      "lastPage" : false
    },
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : "224573",
      "calendarId" : 376,
      "type" : "normal",
      "summary" : "팀<strong class='txt_key'>커뮤니티</strong>, 송도갈비 죽전역점 길어질 경우에 대한 제목은 이렇게 처리됩니다. 죽전역점 길어질 경우에 대한 제목은 이렇게 처리됩니다.",
      "attendees" : "김가람, 양준호, 원준호, 김태환, 백금철, 곽면순, 정동교, 신광호",
      "location" : "",
      "description" : "1차 : https://naver.me/GKoxerd7\n\n2차 : https://naver.me/GmSQaqAG",
      "visibility" : "public",
      "startTime" : "2022-12-13T17:00:00.000+09:00",
      "endTime" : "2022-12-13T23:00:00.000+09:00",
      "timeType" : "timed"
    }, {
      "id" : "220600",
      "calendarId" : 376,
      "type" : "normal",
      "summary" : "팀<strong class='txt_key'>커뮤니티</strong>-송도갈비 죽전역점",
      "attendees" : "김설한, 양준호, 김가람, 백금철, 황형언, 곽면순, 정동교, 신광호",
      "location" : "경기 용인시 수지구 용구대로 2750 / 송도갈비 죽전역점",
      "description" : "8명 11시 40분 예약",
      "visibility" : "public",
      "startTime" : "2022-10-21T11:30:00.000+09:00",
      "endTime" : "2022-10-21T13:00:00.000+09:00",
      "timeType" : "timed"
    }, {
      "id" : "217752",
      "calendarId" : 376,
      "type" : "normal",
      "summary" : "팀<strong class='txt_key'>커뮤니티</strong>",
      "attendees" : "류선영, 변재혁, 김기현, 김태환, 윤승욱, 김학관, 백금철, 김형길, 장수빈, 임승진",
      "location" : "",
      "description" : "",
      "visibility" : "public",
      "startTime" : "2022-09-27T17:00:00.000+09:00",
      "endTime" : "2022-09-27T22:00:00.000+09:00",
      "timeType" : "timed"
    }, {
      "id" : "209445",
      "calendarId" : 376,
      "type" : "normal",
      "summary" : "팀 <strong class='txt_key'>커뮤니티</strong>(예정)",
      "attendees" : "류선영, 변재혁, 김기현, 김태환, 백금철, 김학관, 윤승욱, 김형길, 장수빈, 임승진",
      "location" : "",
      "description" : "메뉴는 양꼬치에 꿔바로우 찡따오\n\n추천장소 : 수지미가훠거양고기 https://blog.naver.com/rabbit_hong/222723075322",
      "visibility" : "public",
      "startTime" : "2022-06-28T18:00:00.000+09:00",
      "endTime" : "2022-06-28T19:00:00.000+09:00",
      "timeType" : "timed"
    }, {
      "id" : "206160",
      "calendarId" : 376,
      "type" : "normal",
      "summary" : "팀<strong class='txt_key'>커뮤니티</strong>",
      "attendees" : "류선영, 변재혁, 김태환, 윤승욱, 김학관, 백금철, 김형길, 장수빈, 임승진",
      "location" : "http://naver.me/FU39hJTH",
      "description" : "",
      "visibility" : "public",
      "startTime" : "2022-05-24T00:00:00.000+09:00",
      "endTime" : "2022-05-24T23:59:59.999+09:00",
      "timeType" : "allday"
    } ]
  });
}

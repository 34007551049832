'use client';

import { useSuspenseQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import * as React from 'react';
import * as checkScreenLockTargetQuery from '../../api/check-screen-lock-target';
import { AppInfo } from '../../types';
import { Context } from './context';

export interface IContextValues {
  appInfo?: AppInfo | null;
  lockState?: boolean;
  appCode?: string;
  simpleCode: string;
  simpleCodeCheck: string;
  newSimpleCode: string;
  newSimpleCodeCheck: string;
  errorMessage: string;
}

export interface IContextActions {
  setAppInfo?: React.Dispatch<React.SetStateAction<AppInfo | null>>;
  setLockState?: React.Dispatch<React.SetStateAction<boolean>>;
  setAppCode?: React.Dispatch<React.SetStateAction<string>>;
  setSimpleCode: React.Dispatch<React.SetStateAction<string>>;
  setSimpleCodeCheck: React.Dispatch<React.SetStateAction<string>>;
  setNewSimpleCode: React.Dispatch<React.SetStateAction<string>>;
  setNewSimpleCodeCheck: React.Dispatch<React.SetStateAction<string>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  appCode: '',
  simpleCode: '',
  simpleCodeCheck: '',
  newSimpleCode: '',
  newSimpleCodeCheck: '',
  errorMessage: '',
});

export const ActionsContext = React.createContext<IContextActions>({
  setAppCode: () => undefined,
  setSimpleCode: () => undefined,
  setSimpleCodeCheck: () => undefined,
  setNewSimpleCode: () => undefined,
  setNewSimpleCodeCheck: () => undefined,
  setErrorMessage: () => undefined,
});

export interface Props {
  appCode?: string;
}

export function Provider({
  appCode: defaultAppCode,
  children,
}: React.PropsWithChildren<Props>) {
  const [appInfo, setAppInfo] = React.useState<AppInfo | null>(null);
  const { data: resCheckScreenLockTarget, refetch } = useSuspenseQuery({
    queryKey: [checkScreenLockTargetQuery.QUERY_KEY],
    queryFn: () =>
      checkScreenLockTargetQuery.checkScreenLockTarget(appInfo?.appCode),
  });
  const [lockState, setLockState] = React.useState<boolean>(false);
  const [appCode, setAppCode] = React.useState(defaultAppCode ?? '');
  const [simpleCode, setSimpleCode] = React.useState('');
  const [simpleCodeCheck, setSimpleCodeCheck] = React.useState('');
  const [newSimpleCode, setNewSimpleCode] = React.useState('');
  const [newSimpleCodeCheck, setNewSimpleCodeCheck] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const pathname = usePathname();

  const locked = (lockedAppInfo: AppInfo) => {
    setAppInfo((prev) => {
      if (prev?.appCode !== lockedAppInfo.appCode) {
        return lockedAppInfo;
      }

      return prev;
    });
  };

  React.useEffect(() => {
    if (appInfo?.appUrl !== pathname) {
      setLockState && setLockState(false);
    }
  }, [pathname, appInfo?.appUrl]);

  // 그룹웨어 컨텐츠를 로드하기 위해, refresh하는 임시 로직.
  React.useEffect(() => {
    if (!appInfo?.appCode) return;

    const isLocked =
      resCheckScreenLockTarget?.data?.isScreenLockTarget ?? false;
    setLockState(isLocked);

    const lastAppCode = sessionStorage.getItem('lastAppCode');
    if (!isLocked && lastAppCode !== appInfo.appCode) {
      sessionStorage.setItem('lastAppCode', appInfo.appCode);
      window.location.reload();
    }
  }, [resCheckScreenLockTarget, pathname]);

  React.useEffect(() => {
    setSimpleCode('');
    setSimpleCodeCheck('');
    setNewSimpleCode('');
    setNewSimpleCodeCheck('');
    setErrorMessage('');
    if (!appInfo) return;
    refetch().catch(console.error);
  }, [appInfo]);

  const valuesContext: IContextValues = {
    appInfo,
    lockState,
    appCode,
    simpleCode,
    simpleCodeCheck,
    newSimpleCode,
    newSimpleCodeCheck,
    errorMessage,
  };

  const actionsContext: IContextActions = {
    setAppInfo,
    setLockState,
    setAppCode,
    setSimpleCode,
    setSimpleCodeCheck,
    setNewSimpleCode,
    setNewSimpleCodeCheck,
    setErrorMessage,
  };

  return (
    <Context value={{ locked }}>
      <ValuesContext value={valuesContext}>
        <ActionsContext value={actionsContext}>{children}</ActionsContext>
      </ValuesContext>
    </Context>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};

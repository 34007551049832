import { 
  HttpResponse, 
  type HttpResponseResolver,
  type PathParams,
  type DefaultBodyType
} from 'msw';

export const resolveTodo: HttpResponseResolver<
  PathParams, 
  DefaultBodyType, 
  DefaultBodyType
> = ({ request }) => {
  const url = new URL(request.url);
  const offset = Number(url.searchParams.get('offset'));
  const page = Number(url.searchParams.get('page'));
  const respData = [ {
    "id" : 24233,
    "todoId" : 3640,
    "todoTitle" : "통합 검색 테스트용 보드",
    "categoryTitle" : "완료",
    "title" : "완료 상태 <strong class='txt_key'>카드</strong>"
  }, {
    "id" : 24232,
    "todoId" : 3640,
    "todoTitle" : "통합 검색 테스트용 보드",
    "categoryTitle" : "진행",
    "title" : "진행 상태 <strong class='txt_key'>카드</strong>"
  }, {
    "id" : 24231,
    "todoId" : 3640,
    "todoTitle" : "통합 검색 테스트용 보드",
    "categoryTitle" : "대기",
    "title" : "대기 상태 <strong class='txt_key'>카드</strong>",
    "contents" : "대기 상태 <strong class='txt_key'>카드</strong>에 대한 통합 검색 테스트용 <strong class='txt_key'>카드</strong>"
  } ];

  return HttpResponse.json({
    "page" : {
      "page" : page,
      "offset" : offset,
      "total" : 3,
      "totalPage" : 1,
      "lastPage" : true
    },
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "data" : [...respData.slice(0, offset)]
  });
}

import { useState } from 'react';
import { useTranslation } from '../../../shared/lib/i18n/client';
import { Button } from '../../../shared/ui/button';
import * as Dialog from '../../../shared/ui/dialog';
import { TextField } from '../../../shared/ui/text-field';
import styles from './style.module.css';

interface Props {
  downloadReason?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onConfirm?: (reason: string) => void;
  onCancel?: () => void;
}

export function DownloadReasonDialog({
  downloadReason,
  open,
  onOpenChange,
  onCancel,
  onConfirm,
}: Props) {
  const { t } = useTranslation('component');
  const [reason, setReason] = useState<string>(downloadReason ?? '');

  const initReason = () => {
    setReason('');
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        className={styles.DownloadReasonDialog}
        position="center"
        animation="popup"
        showCloseButton={false}
        usePortal
        useOverlay
        useAutoClose={false}
      >
        <h1 className={styles.DownloadReasonDialog__Title}>
          {t('widgets.downloadReasonDialog.title')}
        </h1>
        <span className={styles.DownloadReasonDialog__Description}>
          {t('widgets.downloadReasonDialog.description')}
        </span>
        <TextField
          value={reason}
          onChangeValue={setReason}
          size="medium"
          placeholder={t('widgets.downloadReasonDialog.placeholder')}
        />
        <div className={styles.DownloadReasonDialog__ActionButtonGroup}>
          <Button
            variant="ghost"
            colorset="level1"
            shape="rect"
            size="md"
            onClick={() => {
              onCancel && onCancel();
              initReason();
            }}
          >
            {t('widget.MessageDialog.cancel')}
          </Button>
          <Button
            variant="solid"
            colorset="level1"
            shape="rect"
            size="md"
            disabled={!reason || reason.length === 0}
            onClick={() => {
              onConfirm && onConfirm(reason);
              initReason();
            }}
          >
            {t('widget.MessageDialog.confirm')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

'use client';
import * as React from 'react';
import { AppInfo } from '../../types';

interface ScreenLockContextValue {
  locked: (lockedAppInfo: AppInfo) => void;
}

export const Context = React.createContext<ScreenLockContextValue | null>(null);

export const useScreenLock = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useScreenLock must be used within a ScreenLockProvider');
  }
  return context;
};

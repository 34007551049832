'use client';

import { enableMocks } from '@dop-ui/react/shared/lib/mocks/browser';
import { handlers } from './handlers';
import { attendHandlers } from './attend-handlers';

if (process.env.NEXT_PUBLIC_MSW_ENABLED === 'true' || process.env.NEXT_PUBLIC_LOCAL_PROXY_ENABLED === 'true') {
  enableMocks()
    .then((instance) => {
      if (process.env.NEXT_PUBLIC_MSW_ENABLED === 'true') {
        instance.use(...handlers);
      }
      if (process.env.NEXT_PUBLIC_LOCAL_PROXY_ENABLED === 'true') {
        instance.use(...attendHandlers);
      }
    })
    .catch(console.error);
}

import { HttpResponse } from 'msw';

export function getReportDeptListResolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 748,
      "createdAt" : "2020-01-02T07:24:01.846+09:00",
      "updatedAt" : "2025-01-02T10:55:26.303+09:00",
      "folders" : [ {
        "id" : 1301,
        "createdAt" : "2020-01-07T09:54:44.567+09:00",
        "updatedAt" : "2025-01-10T00:10:01.201+09:00",
        "recurrence" : "FREQ=WEEKLY;INTERVAL=1;BYDAY=TH",
        "name" : "포털서비스개발팀 주간보고",
        "description" : "포털서비스개발팀 주간보고입니다.\n* 주간보고 회의 일시 : 매주 목요일 14시",
        "formFlag" : false,
        "totalCount" : 1917,
        "totalSeries" : 263,
        "type" : "PERIODIC",
        "publicOption" : "OPEN",
        "status" : "ACTIVE",
        "shared" : false,
        "department" : {
          "id" : 748,
          "name" : "포털서비스개발팀",
          "code" : "O000000127",
          "email" : "groupwaredev@daou.co.kr",
          "emailId" : "groupwaredev",
          "memberCount" : 13,
          "childrenCount" : 0,
          "parentId" : 992,
          "parentCode" : "O000000186",
          "sortOrder" : 2,
          "depth" : 0,
          "deptAlias" : "groupwaredev",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "포털서비스개발팀"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : false,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : true
      }, {
        "id" : 1333,
        "createdAt" : "2020-02-27T15:41:43.901+09:00",
        "updatedAt" : "2021-10-09T01:04:14.520+09:00",
        "recurrence" : "FREQ=WEEKLY",
        "name" : "재택근무 일일보고",
        "description" : "",
        "formFlag" : true,
        "totalCount" : 1001,
        "totalSeries" : 0,
        "type" : "OCCASIONAL",
        "publicOption" : "OPEN",
        "status" : "ACTIVE",
        "shared" : false,
        "department" : {
          "id" : 748,
          "name" : "포털서비스개발팀",
          "code" : "O000000127",
          "email" : "groupwaredev@daou.co.kr",
          "emailId" : "groupwaredev",
          "memberCount" : 13,
          "childrenCount" : 0,
          "parentId" : 992,
          "parentCode" : "O000000186",
          "sortOrder" : 2,
          "depth" : 0,
          "deptAlias" : "groupwaredev",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "포털서비스개발팀"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : true,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : false
      }, {
        "id" : 2200,
        "createdAt" : "2024-04-18T14:07:36.653+09:00",
        "updatedAt" : "2025-01-16T00:10:00.280+09:00",
        "recurrence" : "FREQ=WEEKLY;INTERVAL=1;BYDAY=WE",
        "name" : "차세대 그룹웨어 주간보고",
        "description" : "차세대 그룹웨어 주간보고",
        "formFlag" : true,
        "totalCount" : 138,
        "totalSeries" : 41,
        "type" : "PERIODIC",
        "publicOption" : "OPEN",
        "status" : "ACTIVE",
        "shared" : false,
        "department" : {
          "id" : 748,
          "name" : "포털서비스개발팀",
          "code" : "O000000127",
          "email" : "groupwaredev@daou.co.kr",
          "emailId" : "groupwaredev",
          "memberCount" : 13,
          "childrenCount" : 0,
          "parentId" : 992,
          "parentCode" : "O000000186",
          "sortOrder" : 2,
          "depth" : 0,
          "deptAlias" : "groupwaredev",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "포털서비스개발팀"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : false,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : false
      }, {
        "id" : 2223,
        "createdAt" : "2024-05-16T10:29:50.090+09:00",
        "updatedAt" : "2024-05-16T10:29:50.091+09:00",
        "recurrence" : "FREQ=MONTHLY;INTERVAL=30;BYDAY=TH;BYSETPOS=3",
        "name" : "가젯 테스트를 위한 보고 (4)",
        "description" : "",
        "formFlag" : false,
        "totalCount" : 2,
        "totalSeries" : 1,
        "type" : "PERIODIC",
        "publicOption" : "OPEN",
        "status" : "ACTIVE",
        "shared" : false,
        "department" : {
          "id" : 748,
          "name" : "포털서비스개발팀",
          "code" : "O000000127",
          "email" : "groupwaredev@daou.co.kr",
          "emailId" : "groupwaredev",
          "memberCount" : 13,
          "childrenCount" : 0,
          "parentId" : 992,
          "parentCode" : "O000000186",
          "sortOrder" : 2,
          "depth" : 0,
          "deptAlias" : "groupwaredev",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "포털서비스개발팀"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : false,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : false
      } ],
      "name" : "포털서비스개발팀",
      "shared" : false,
      "deleted" : false,
      "actions" : {
        "updatable" : false,
        "removable" : false,
        "writable" : false,
        "managable" : false,
        "readable" : false
      }
    }, {
      "id" : 99,
      "createdAt" : "2013-03-22T16:43:12.518+09:00",
      "updatedAt" : "2025-01-02T10:55:26.303+09:00",
      "folders" : [ {
        "id" : 1655,
        "createdAt" : "2021-04-15T14:47:06.713+09:00",
        "updatedAt" : "2024-12-31T15:46:51.498+09:00",
        "recurrence" : "FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=25",
        "name" : "비즈마케팅개발본부 월간보고",
        "description" : "",
        "formFlag" : true,
        "totalCount" : 270,
        "totalSeries" : 47,
        "type" : "PERIODIC",
        "publicOption" : "OPEN",
        "status" : "ACTIVE",
        "shared" : true,
        "department" : {
          "id" : 99,
          "name" : "비즈마케팅개발본부",
          "code" : "0000000300",
          "email" : "servicedev@daou.co.kr",
          "emailId" : "servicedev",
          "memberCount" : 1,
          "childrenCount" : 7,
          "parentId" : 780,
          "parentCode" : "O000000138",
          "sortOrder" : 1,
          "depth" : 0,
          "deptAlias" : "servicedev",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "비즈마케팅개발본부"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : false,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : false
      } ],
      "name" : "비즈마케팅개발본부",
      "shared" : true,
      "deleted" : false,
      "actions" : {
        "updatable" : false,
        "removable" : false,
        "writable" : false,
        "managable" : false,
        "readable" : false
      }
    }, {
      "id" : 17,
      "createdAt" : "2013-03-21T16:05:08.153+09:00",
      "updatedAt" : "2025-01-02T10:55:26.303+09:00",
      "folders" : [ {
        "id" : 2245,
        "createdAt" : "2024-06-25T17:58:04.927+09:00",
        "updatedAt" : "2025-01-10T00:10:00.159+09:00",
        "recurrence" : "FREQ=WEEKLY;INTERVAL=1;BYDAY=TH",
        "name" : "비즈앱개발본부 주간회의",
        "description" : "",
        "formFlag" : true,
        "totalCount" : 237,
        "totalSeries" : 31,
        "type" : "PERIODIC",
        "publicOption" : "OPEN",
        "status" : "ACTIVE",
        "shared" : true,
        "department" : {
          "id" : 17,
          "name" : "비즈앱개발본부",
          "code" : "0000000324",
          "email" : "swplat@daou.co.kr",
          "emailId" : "swplat",
          "memberCount" : 1,
          "childrenCount" : 5,
          "parentId" : 780,
          "parentCode" : "O000000138",
          "sortOrder" : 2,
          "depth" : 0,
          "deptAlias" : "swplat",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "비즈앱개발본부"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : true,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : false
      }, {
        "id" : 2247,
        "createdAt" : "2024-06-28T08:51:28.187+09:00",
        "updatedAt" : "2024-12-27T00:10:00.212+09:00",
        "recurrence" : "FREQ=MONTHLY;INTERVAL=1;BYDAY=TH;BYSETPOS=-1",
        "name" : "비즈앱개발본부 월간회의",
        "description" : "",
        "formFlag" : true,
        "totalCount" : 79,
        "totalSeries" : 11,
        "type" : "PERIODIC",
        "publicOption" : "OPEN",
        "status" : "ACTIVE",
        "shared" : true,
        "department" : {
          "id" : 17,
          "name" : "비즈앱개발본부",
          "code" : "0000000324",
          "email" : "swplat@daou.co.kr",
          "emailId" : "swplat",
          "memberCount" : 1,
          "childrenCount" : 5,
          "parentId" : 780,
          "parentCode" : "O000000138",
          "sortOrder" : 2,
          "depth" : 0,
          "deptAlias" : "swplat",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "비즈앱개발본부"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : true,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : false
      } ],
      "name" : "비즈앱개발본부",
      "shared" : true,
      "deleted" : false,
      "actions" : {
        "updatable" : false,
        "removable" : false,
        "writable" : false,
        "managable" : false,
        "readable" : false
      }
    }, {
      "id" : 59,
      "createdAt" : "2013-03-22T09:40:01.207+09:00",
      "updatedAt" : "2025-01-16T16:37:24.729+09:00",
      "folders" : [ {
        "id" : 2344,
        "createdAt" : "2024-12-20T17:22:47.031+09:00",
        "updatedAt" : "2025-01-16T00:10:00.349+09:00",
        "recurrence" : "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR",
        "name" : "주간보고 양식 테스트",
        "description" : "주간보고 양식 테스트",
        "formFlag" : false,
        "totalCount" : 120,
        "totalSeries" : 15,
        "type" : "PERIODIC",
        "publicOption" : "CLOSED",
        "status" : "ACTIVE",
        "shared" : true,
        "department" : {
          "id" : 59,
          "name" : "플랫폼기획팀",
          "code" : "0000000600",
          "email" : "plan@daou.co.kr",
          "emailId" : "plan",
          "memberCount" : 12,
          "childrenCount" : 0,
          "parentId" : 17,
          "parentCode" : "0000000324",
          "sortOrder" : 5,
          "depth" : 0,
          "deptAlias" : "plan",
          "deletedDept" : false,
          "sortFlag" : true,
          "useReception" : true,
          "useReference" : false,
          "additions" : {
            "koName" : "플랫폼기획팀"
          },
          "companyId" : 4,
          "groupDepth" : 0
        },
        "actions" : {
          "updatable" : false,
          "removable" : false,
          "writable" : true,
          "managable" : false,
          "readable" : false
        },
        "favorite" : false,
        "attachSize" : 0,
        "newReport" : false
      } ],
      "name" : "플랫폼기획팀",
      "shared" : true,
      "deleted" : false,
      "actions" : {
        "updatable" : false,
        "removable" : false,
        "writable" : false,
        "managable" : false,
        "readable" : false
      }
    } ]
  });
}


const AuthInfo = { // 테스트용
    "device": "PC",
    "userIpaddr": "172.21.27.75",
    "requestId": 1983693884,
    "userId": 273,
    "companyUuid": "1"
  }

export function attendApiResolver({ request }) {
    request.headers.append('X-Auth-Info', JSON.stringify(AuthInfo));
}
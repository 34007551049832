import { HttpResponse } from 'msw';

export function getBoardMenuOwnerResolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "name" : "포털서비스개발팀",
      "ownerId" : 748,
      "ownerType" : "Department"
    }, {
      "name" : "비즈앱개발본부",
      "ownerId" : 17,
      "ownerType" : "Department"
    }, {
      "name" : "플랫폼기획팀",
      "ownerId" : 59,
      "ownerType" : "Department"
    }, {
      "name" : "비즈플랫폼팀",
      "ownerId" : 61,
      "ownerType" : "Department"
    }, {
      "name" : "품질관리팀",
      "ownerId" : 800,
      "ownerType" : "Department"
    }, {
      "name" : "전사게시판",
      "ownerId" : 4,
      "ownerType" : "Company"
    } ]
  });
}

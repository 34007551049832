import * as React from 'react';
import {
  DownloadReasonDialogResponse,
  DownloadReasonDialogState,
} from '../types';

export const useDownloadReasonDialogState = () => {
  const [dialogState, setDialogState] =
    React.useState<DownloadReasonDialogState>({
      open: false,
      resolve: null,
    });

  const openDownloadReasonDialog = React.useCallback(
    (defaultReason?: string) => {
      return new Promise<DownloadReasonDialogResponse>((resolve) => {
        setDialogState({
          open: true,
          defaultReason,
          resolve,
        });
      });
    },
    [],
  );

  const close = () => {
    setDialogState({
      open: false,
      resolve: null,
    });
  };

  const handleResolve = React.useCallback(
    (reason?: string) => {
      if (dialogState.resolve) {
        dialogState.resolve({
          isConfirmed: true,
          reason,
        });
        close();
      }
    },
    [dialogState],
  );

  const handleReject = React.useCallback(() => {
    if (dialogState.resolve) {
      dialogState.resolve({
        isConfirmed: false,
      });
      close();
    }
  }, [dialogState]);

  return { dialogState, handleResolve, handleReject, openDownloadReasonDialog };
};

import { HttpResponse } from 'msw';

export function getDocsFolderMenuResolver() {
  return HttpResponse.json([ {
    "id" : 390,
    "createdAt" : "2024-10-22T11:21:47.943+09:00",
    "updatedAt" : "2024-10-29T08:38:09.950+09:00",
    "name" : "백신",
    "seq" : 4,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 16,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 보안 > 백신",
    "parent" : [ {
      "id" : 16,
      "seq" : 6,
      "depth" : 3,
      "name" : "보안"
    }, {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 40,
    "createdAt" : "2016-12-30T14:37:47.734+09:00",
    "updatedAt" : "2016-12-30T14:38:58.156+09:00",
    "name" : "기획",
    "seq" : 1,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 기획",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 360,
    "createdAt" : "2023-09-26T15:54:54.503+09:00",
    "updatedAt" : "2023-09-26T15:55:15.078+09:00",
    "name" : "DB접근제어(CHAXRAMAX)",
    "seq" : 2,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 16,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 보안 > DB접근제어(CHAXRAMAX)",
    "parent" : [ {
      "id" : 16,
      "seq" : 6,
      "depth" : 3,
      "name" : "보안"
    }, {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 10,
    "createdAt" : "2016-09-12T15:15:58.794+09:00",
    "updatedAt" : "2017-06-21T13:44:23.254+09:00",
    "name" : "생활백서",
    "seq" : 2,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 1,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서",
    "parent" : [ ],
    "children" : [ ]
  }, {
    "id" : 11,
    "createdAt" : "2016-09-12T15:17:18.258+09:00",
    "updatedAt" : "2016-11-02T08:23:35.570+09:00",
    "name" : "인사",
    "seq" : 0,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 인사",
    "lastDocsCompleteDate" : "2025-01-16T11:07:25.217+09:00",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 12,
    "createdAt" : "2016-09-12T15:17:22.529+09:00",
    "updatedAt" : "2016-12-30T14:38:45.892+09:00",
    "name" : "재경",
    "seq" : 2,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 재경",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 13,
    "createdAt" : "2016-09-12T15:17:27.826+09:00",
    "updatedAt" : "2016-12-30T14:38:30.612+09:00",
    "name" : "법무",
    "seq" : 3,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 법무",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 14,
    "createdAt" : "2016-09-12T15:17:32.017+09:00",
    "updatedAt" : "2022-12-30T09:41:56.031+09:00",
    "name" : "지원",
    "seq" : 4,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 지원",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 270,
    "createdAt" : "2023-06-23T17:55:33.901+09:00",
    "updatedAt" : "2023-06-23T17:55:33.901+09:00",
    "name" : "소만사DLP",
    "seq" : 0,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 16,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 보안 > 소만사DLP",
    "parent" : [ {
      "id" : 16,
      "seq" : 6,
      "depth" : 3,
      "name" : "보안"
    }, {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 15,
    "createdAt" : "2016-09-12T15:17:39.698+09:00",
    "updatedAt" : "2018-02-27T10:38:09.617+09:00",
    "name" : "사업지원",
    "seq" : 5,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 사업지원",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 271,
    "createdAt" : "2023-06-23T17:55:56.792+09:00",
    "updatedAt" : "2023-06-23T17:55:56.792+09:00",
    "name" : "서버접근제어(HIWARE)",
    "seq" : 1,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 16,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 보안 > 서버접근제어(HIWARE)",
    "parent" : [ {
      "id" : 16,
      "seq" : 6,
      "depth" : 3,
      "name" : "보안"
    }, {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 240,
    "createdAt" : "2021-05-18T11:01:04.749+09:00",
    "updatedAt" : "2021-05-18T11:03:57.073+09:00",
    "name" : "리스크관리",
    "seq" : 8,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 리스크관리",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 16,
    "createdAt" : "2016-09-12T15:17:43.552+09:00",
    "updatedAt" : "2024-10-29T08:38:56.493+09:00",
    "name" : "보안",
    "seq" : 6,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 보안",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 17,
    "createdAt" : "2016-09-12T15:17:47.537+09:00",
    "updatedAt" : "2021-04-15T09:39:27.691+09:00",
    "name" : "IT지원",
    "seq" : 7,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > IT지원",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 241,
    "createdAt" : "2021-05-18T11:04:04.044+09:00",
    "updatedAt" : "2021-05-18T11:04:09.362+09:00",
    "name" : "내부회계관리제도",
    "seq" : 9,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 내부회계관리제도",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 401,
    "createdAt" : "2024-10-29T08:38:56.443+09:00",
    "updatedAt" : "2024-10-29T08:38:56.443+09:00",
    "name" : "망연계(자료교환)",
    "seq" : 5,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 16,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 보안 > 망연계(자료교환)",
    "parent" : [ {
      "id" : 16,
      "seq" : 6,
      "depth" : 3,
      "name" : "보안"
    }, {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 18,
    "createdAt" : "2016-09-12T15:17:52.994+09:00",
    "updatedAt" : "2021-05-18T11:04:09.362+09:00",
    "name" : "기타",
    "seq" : 10,
    "state" : "NORMAL",
    "docYear" : 0,
    "useDocNum" : false,
    "useApproved" : true,
    "parentId" : 10,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 기타",
    "parent" : [ {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  }, {
    "id" : 380,
    "createdAt" : "2024-02-15T17:42:40.670+09:00",
    "updatedAt" : "2024-02-15T18:56:21.433+09:00",
    "name" : "유해사이트차단솔루션",
    "seq" : 3,
    "state" : "NORMAL",
    "docYear" : 5,
    "useDocNum" : false,
    "useApproved" : false,
    "parentId" : 16,
    "favorite" : false,
    "readable" : true,
    "writable" : false,
    "parentPathName" : "생활백서 > 보안 > 유해사이트차단솔루션",
    "parent" : [ {
      "id" : 16,
      "seq" : 6,
      "depth" : 3,
      "name" : "보안"
    }, {
      "id" : 10,
      "seq" : 2,
      "depth" : 2,
      "name" : "생활백서"
    } ],
    "children" : [ ]
  } ]);
}

'use client';

import type { PropsWithChildren } from 'react';
import {
  TranslationsProvider,
  type ImportFn as i18nResourceImportFn,
} from '../../../shared/lib/i18n';
import { MessageDialogProvider } from '../../../shared/ui/message-dialog';
import {
  dirToAnchor,
  TOAST_ANCHOR,
  ToastMessageProvider,
  type ToastAnchor,
  type ToastDirection,
} from '../../../shared/ui/toast';
import * as Tooltip from '../../../shared/ui/tooltip';
import { DownloadReasonDialogProvider } from '../../../widgets/download-resaon-dialog';
import { ScreenLockProvider } from '../../../widgets/screen-lock';

export interface Props {
  /** @property 현재 다국어 코드 */
  locale?: string;
  /** @property 추가 다국어 리소스 로더 */
  i18nResourceLoader?: i18nResourceImportFn;
  /** @property i18n 네임스페이스 */
  i18nNs?: string[];
  /** @property Toast 출력 방향 지정 (기본값 'bottom-right') */
  toastAnchor?: ToastAnchor;
  /** @property Toast 출력 시간 지정 (기본값 4000) */
  toastDuration?: number;
  /** @property Toast Size (기본값 md, 400px) */
  toastSize?: 'sm' | 'md' | 'lg';
  /** @deprecated @property Toast 출력 방향 지정 (기본값 'right') */
  toastDir?: ToastDirection;
}

/**
 * dop-ui의 전역 Provider
 * @description storybook에서 독립적으로 사용하기 위한 Provider
 * @returns
 */
export function DopuiRootProvider({
  children,
  locale,
  i18nResourceLoader = () => Promise.resolve({}),
  i18nNs,
  toastAnchor,
  toastDuration = 4000,
  toastSize = 'md',
  toastDir,
}: PropsWithChildren<Props>) {
  const toastAnchorValue =
    typeof toastDir !== 'undefined' && typeof toastAnchor === 'undefined'
      ? dirToAnchor(toastDir)
      : typeof toastAnchor === 'undefined'
        ? TOAST_ANCHOR.BOTTOM_RIGHT
        : toastAnchor;

  return (
    <TranslationsProvider
      locale={locale}
      loadResource={i18nResourceLoader}
      namespaces={i18nNs}
    >
      <ScreenLockProvider>
        <Tooltip.Provider>
          <MessageDialogProvider>
            <DownloadReasonDialogProvider>
              <ToastMessageProvider
                anchor={toastAnchorValue}
                duration={toastDuration}
                size={toastSize}
              >
                {children}
              </ToastMessageProvider>
            </DownloadReasonDialogProvider>
          </MessageDialogProvider>
        </Tooltip.Provider>
      </ScreenLockProvider>
    </TranslationsProvider>
  );
}

DopuiRootProvider.displayName = 'Dopui.RootProvider';

export default DopuiRootProvider;

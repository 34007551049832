import * as React from 'react';
import { DownloadReasonDialogCallFunction } from '../types';

interface DownloadReasonDialogContextValue {
  openDownloadReasonDialog: DownloadReasonDialogCallFunction;
}

export const Context =
  React.createContext<DownloadReasonDialogContextValue | null>(null);

export const useDownloadReasonDialog = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error(
      'useDownloadReasonDialog must be used within a DownloadReasonDialogProvider',
    );
  }

  return context;
};

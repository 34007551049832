import { HttpResponse } from 'msw';

export function getDepartmentListResolver() {
  return HttpResponse.json({
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 748,
      "name" : "포털서비스개발팀",
      "code" : "O000000127",
      "email" : "groupwaredev@daou.co.kr",
      "emailId" : "groupwaredev",
      "memberCount" : 13,
      "childrenCount" : 0,
      "parentId" : 992,
      "parentCode" : "O000000186",
      "sortOrder" : 2,
      "depth" : 0,
      "deptAlias" : "groupwaredev",
      "deletedDept" : false,
      "sortFlag" : true,
      "useReception" : true,
      "useReference" : false,
      "additions" : {
        "koName" : "포털서비스개발팀"
      },
      "companyId" : 4,
      "groupDepth" : 0,
      "koName" : "포털서비스개발팀",
      "userDepartmentOrder" : 1
    } ]
  });
}

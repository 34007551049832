import { HttpResponse } from 'msw';

export function resolveReport() {
  return HttpResponse.json({
    "page" : {
      "page" : 0,
      "offset" : 5,
      "total" : 80,
      "totalPage" : 16,
      "lastPage" : false
    },
    "hasNext" : true,
    "message" : "OK",
    "code" : "200",
    "__go_checksum__" : true,
    "data" : [ {
      "id" : 212048,
      "folderId" : 2344,
      "folderName" : "주간보고 양식 테스트",
      "folderType" : "PERIODIC",
      "folderDeptName" : "플랫폼기획팀",
      "seriesId" : 39781,
      "seriesNo" : 1,
      "reporter" : {
        "id" : 5140,
        "loginId" : "seoch",
        "name" : "서창호",
        "email" : "seoch@daou.co.kr",
        "originalEmail" : "seoch@daou.co.kr",
        "position" : "책임",
        "thumbnail" : "/thumb/user/small/2232000-39973",
        "status" : "ONLINE",
        "employeeNumber" : "4148",
        "deptId" : 59,
        "deptName" : "플랫폼기획팀",
        "companyId" : 4,
        "companyName" : "(주)다우기술",
        "manager" : false,
        "master" : false,
        "moderator" : false,
        "fullName" : "서창호 책임"
      },
      "submittedAt" : "2024-12-20T17:24:38.619+09:00",
      "contents" : " &#45813;&#51109; &#54532;&#47212;&#54532;&#53944; &#52628;&#44032; : &#44172;&#49884;&#54032;, <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &#50836;&#50557; &#44592;&#45733; &#48372;&#47448; - &#48373;&#51648;&#47792; &#50672;&#46041; (&#47196;&#44536;&#51064; &#48372;&#50504;&#51060;&#49800; &#51068;&#51221;&#48320;&#44221;"
    }, {
      "id" : 211570,
      "folderId" : 2245,
      "folderName" : "비즈앱개발본부 주간회의",
      "folderType" : "PERIODIC",
      "folderDeptName" : "비즈앱개발본부",
      "seriesId" : 39740,
      "seriesNo" : 27,
      "reporter" : {
        "id" : 5140,
        "loginId" : "seoch",
        "name" : "서창호",
        "email" : "seoch@daou.co.kr",
        "originalEmail" : "seoch@daou.co.kr",
        "position" : "책임",
        "thumbnail" : "/thumb/user/small/2232000-39973",
        "status" : "ONLINE",
        "employeeNumber" : "4148",
        "deptId" : 59,
        "deptName" : "플랫폼기획팀",
        "companyId" : 4,
        "companyName" : "(주)다우기술",
        "manager" : false,
        "master" : false,
        "moderator" : false,
        "fullName" : "서창호 책임"
      },
      "submittedAt" : "2024-12-19T18:31:40.665+09:00",
      "contents" : "&nbsp;&#52628;&#44032; &nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&#44172;&#49884;&#54032;,&nbsp;<strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong>&nbsp;&#50836;&#50557;&nbsp;&#44592;&#45733;&nbsp;&#48372;&#47448; &nbsp;&nbsp;-&nbsp;&#48373;&#51648;&#47792;&nbsp;&#50672;&#46041;&nbsp;(&#47196;&#44536;&#51064;&nbsp;&#48372;&#50504;&#51060;&#49800;"
    }, {
      "id" : 211152,
      "folderId" : 2245,
      "folderName" : "비즈앱개발본부 주간회의",
      "folderType" : "PERIODIC",
      "folderDeptName" : "비즈앱개발본부",
      "seriesId" : 39672,
      "seriesNo" : 26,
      "reporter" : {
        "id" : 384,
        "loginId" : "leejh",
        "name" : "이재희",
        "email" : "leejh@daou.co.kr",
        "originalEmail" : "leejh@daou.co.kr",
        "position" : "책임",
        "thumbnail" : "/thumb/user/small/490933-69619",
        "status" : "ONLINE",
        "employeeNumber" : "2197",
        "deptId" : 992,
        "deptName" : "다우오피스개발부,포털서비스개발팀",
        "companyId" : 4,
        "companyName" : "(주)다우기술",
        "manager" : false,
        "master" : false,
        "moderator" : false,
        "fullName" : "이재희 책임"
      },
      "submittedAt" : "2024-12-13T09:59:09.844+09:00",
      "contents" : " &#44160;&#49353; &gt; &#50545;&#44288;&#47532; &#44060;&#48156; (~12/20) : <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong>/&#48372;&#44256;/Works/&#45824;&#54868; &#49444;&#51221; &#44060;&#48156; &#50756;&#47308; - &#51064;&#49324; &#44592;&#45733; &#44060;&#48156; (~12/13 -&gt; 12/20"
    }, {
      "id" : 210641,
      "folderId" : 2245,
      "folderName" : "비즈앱개발본부 주간회의",
      "folderType" : "PERIODIC",
      "folderDeptName" : "비즈앱개발본부",
      "seriesId" : 39596,
      "seriesNo" : 25,
      "reporter" : {
        "id" : 5491,
        "loginId" : "kbsbroad",
        "name" : "강봉수",
        "email" : "kbsbroad@daou.co.kr",
        "originalEmail" : "kbsbroad@daou.co.kr",
        "position" : "책임",
        "thumbnail" : "/thumb/user/small/2875751-2619",
        "status" : "ONLINE",
        "employeeNumber" : "4349",
        "deptId" : 748,
        "deptName" : "포털서비스개발팀",
        "companyId" : 4,
        "companyName" : "(주)다우기술",
        "manager" : false,
        "master" : false,
        "moderator" : false,
        "fullName" : "강봉수 책임"
      },
      "submittedAt" : "2024-12-06T10:20:24.538+09:00",
      "contents" : " &#49444;&#47928; &#54788;&#54889; &#49444;&#51221; &#44060;&#48156; &#50756;&#47308; &nbsp; - &#48372;&#44256; &#49444;&#51221; &#44060;&#48156; &#50756;&#47308; &nbsp; - &#51204;&#52404; <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &#54788;&#54889; &#44060;&#48156; &#51652;&#54665; &#51473;"
    }, {
      "id" : 210646,
      "folderId" : 2245,
      "folderName" : "비즈앱개발본부 주간회의",
      "folderType" : "PERIODIC",
      "folderDeptName" : "비즈앱개발본부",
      "seriesId" : 39596,
      "seriesNo" : 25,
      "reporter" : {
        "id" : 396,
        "loginId" : "limsj",
        "name" : "임승진",
        "email" : "limsj@daou.co.kr",
        "originalEmail" : "limsj@daou.co.kr",
        "position" : "책임",
        "thumbnail" : "/thumb/user/small/490931-74982",
        "status" : "ONLINE",
        "employeeNumber" : "2195",
        "deptId" : 61,
        "deptName" : "비즈플랫폼팀",
        "companyId" : 4,
        "companyName" : "(주)다우기술",
        "manager" : false,
        "master" : false,
        "moderator" : false,
        "fullName" : "임승진 책임"
      },
      "submittedAt" : "2024-12-06T09:32:02.048+09:00",
      "contents" : " &nbsp; &nbsp;:&nbsp;&#50640;&#47084;&#54168;&#51060;&#51648; tomcat &#48260;&#51204;&#51221;&#48372; &#45432;&#52636; &nbsp; &nbsp;: <strong class='txt_key'>&#52964;&#48036;&#45768;&#54000;</strong> &gt; &#51228;&#47785; &#53356;&#47196;&#49828;&#49324;&#51060;&#53944; &#49828;&#53356;&#47549;&#54021; &nbsp;&nbsp; &#51060;&#49800;&#49324;&#54637; /&#44592;&#53440; &nbsp;"
    } ]
  });
}
